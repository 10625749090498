import {useContext, useEffect, useState} from 'react';
import {v4 as uuid} from 'uuid';
import m from 'moment';

import useData from './useData';

import apiGetProjectScenario from '../providers/api/get-project-scenario';
import apiCreateProjectScenario from '../providers/api/create-project-scenario';
import apiUpdateScenarioInfo from '../providers/api/update-scenario-info';
import apiSetDefaultScenario from '../providers/api/set-default-scenario';
import apiDeleteScenario from '../providers/api/delete-scenario';

/**
 * Creates a project object.
 *
 * @param {boolean|object} initialData
 * @param {boolean|object} initialScenarioData
 */
const useProject = (initialData = false, initialScenarioData = false) => {
	initialData = initialData ? initialData : {status: 'Planning'};
	initialScenarioData = initialScenarioData ? initialScenarioData : {};

	const initialScenario = {
		id: 'initial',
		title: 'Initial',
		isDefault: true,
		data: {
			...initialScenarioData,
			kickoff: m().format('YYYYMMDD'),
		},
	};

	const data = useContext(useData(initialData));
	const scenario = useContext(useData(initialScenario));

	const [scenariosList, setScenariosList] = useState([]);

	useEffect(() => reset(initialData), []);

	return Object.freeze({
		data,
		scenario,
		reset,
		createScenario,
		changeScenario,
		changeDefaultScenario,
		updateScenarioInfo,
		deleteScenario,
		setScenariosList,
		getScenariosList,
	});

	/**
	 * Resets the project data.
	 *
	 * @param {object} newData
	 * @param {object|null} activeScenario
	 */
	function reset (newData, activeScenario = null) {
		data.reset(newData);
		if (activeScenario !== null) scenario.reset(activeScenario);
	}

	/**
	 * Creates a new scenario and sets it as the active one.
	 *
	 * @param newScenario
	 * @returns {Promise<boolean>}
	 */
	async function createScenario (newScenario) {
		newScenario = structuredClone({
			...scenario.getData(),
			...newScenario,
			isDefault: false,
			id: uuid(),
		});

		const newScenariosList = await apiCreateProjectScenario(data.get('id'), newScenario);

		if (newScenariosList) {
			scenario.reset(newScenario);
			setScenariosList(newScenariosList);
		}
	}

	/**
	 * Changes the active scenario.
	 *
	 * @param {string} id
	 */
	async function changeScenario (id) {
		const s = await apiGetProjectScenario(data.get('id'), id);

		if (!scenario) return;

		scenario.reset(s);
	}

	/**
	 * Changes the default scenario.
	 *
	 * @param {string} id
	 */
	async function changeDefaultScenario (id) {
		const newScenariosList = await apiSetDefaultScenario(data.get('id'), id);

		if (newScenariosList) setScenariosList(newScenariosList);
	}

	/**
	 * Updates information about a scenario.
	 *
	 * @param {string} scenario
	 * @param {object} info
	 */
	async function updateScenarioInfo (scenario, info) {
		const newScenariosList = await apiUpdateScenarioInfo(data.get('id'), scenario, info);

		if (newScenariosList) setScenariosList(newScenariosList);
	}

	/**
	 * Deletes a scenario.
	 *
	 * @param {string} id
	 */
	async function deleteScenario(id) {
		const newScenariosList = await apiDeleteScenario(data.get('id'), id);

		if (newScenariosList) setScenariosList(newScenariosList);
	}

	/**
	 * Retrieves the list of scenarios.
	 *
	 * @returns {Array}
	 */
	function getScenariosList () {
		return scenariosList;
	}
};

export default useProject;
