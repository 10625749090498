import {useContext, useEffect, useState} from 'react';

import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Link,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import useData from '../../../hooks/useData';

import Accordion from '../../../components/Accordion';
import DataControl from '../../../components/DataControl';

const INITIAL_OPTIONS = {
	title: '',
	primaryAction: () => {},
};

const EntryDialog = ({mRef, excludeFields = [], type, switchType}) => {
	const {user} = useAuth();

	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState(INITIAL_OPTIONS);

	const entry = useContext(useData({type}));

	const methods = {
		open: (opts, data = {}) => {
			setOptions(opts);
			entry[opts.reset ? 'reset' : 'update'](data);
			setOpen(true);
		},
	};

	useEffect(() => {
		mRef.current = methods;
	}, []);

	/**
	 * Check if the entity accepts a specific field.
	 */
	const accepts = field => {
		if (
			entry.get('type') === 'feature' &&
			field === 'wires'
		) return false;

		if (typeof field === 'string') {
			return !excludeFields.includes(field);
		} else {
			return field.filter(accepts).length > 0;
		}
	};

	/**
	 * Handle what happens when the user cancels the interaction.
	 */
	const handleCancel = () => setOpen(false);

	/**
	 * Handle what happens when the user clicks on the primary action button.
	 */
	const handlePrimaryAction = () => {
		if (!entry.validate()) return;

		options.primaryAction(entry.getData());
		setOptions(INITIAL_OPTIONS);
		setOpen(false);
	};

	/**
	 * Handle what happens if the user decides to create a module instead of an integration.
	 */
	const handleSwitchContentType = () => {
		setOpen(false);
		switchType();
	};

	// Check if this entry has notes.
	const hasNotes = Object.values(entry.get('notes') || {}).filter(n => Boolean(n)).length > 0;

	return (
		<Dialog open={open} maxWidth="md" scroll="body" onClose={handleCancel}>
			<DialogTitle>{options.title}</DialogTitle>
			<DialogContent>
				<Box paddingTop={1.5}>
					<Grid container columnSpacing={2} rowSpacing={3}>
						{accepts('title') && (
							<Grid item xs={accepts('type') ? 8 : 12}>
								<DataControl
									component={TextField}
									label="Title"
									name="title"
									controller={entry}
									permissionType="edit_details"
									autoFocus
									required
								/>
							</Grid>
						)}

						{accepts('type') && (
							<Grid item xs={4}>
								<DataControl
									component={Select}
									label="Type"
									name="type"
									controller={entry}
									permissionType="edit_details"
									required>
									<MenuItem value="feature">Feature</MenuItem>
									<MenuItem value="page">Page</MenuItem>
								</DataControl>
							</Grid>
						)}

						{type === 'integration' && (
							<Grid item xs={12}>
								<Alert severity="warning">
									If this integration requires Wires or
									Designs, <Link sx={{color: 'white', cursor: 'pointer'}} onClick={handleSwitchContentType}>create a module instead</Link>.
								</Alert>
							</Grid>
						)}

						<Grid item xs={12}>
							<Accordion title="Estimates" defaultCollapsed={!user.can('edit_estimates')}>
								<Grid container columnSpacing={2} rowSpacing={3}>
									{accepts(['wires', 'design']) && (
										<Grid item xs={12}>
											<Typography variant="subtitle2">Design</Typography>
										</Grid>
									)}

									{accepts('wires') && (
										<>
											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Wires duration (days)"
													name="wiresDays"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('wiresHours')) || options.isHome}
												/>
											</Grid>

											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Wires estimate (billable hours)"
													name="wiresHours"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('wiresDays')) || options.isHome}
												/>
											</Grid>
										</>
									)}

									{accepts('design') && (
										<>
											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Desktop duration (days)"
													name="designDaysDesktop"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('designHoursDesktop')) || options.isHome}
												/>
											</Grid>

											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Desktop estimate (billable hours)"
													name="designHoursDesktop"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('designDaysDesktop')) || options.isHome}
												/>
											</Grid>

											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Mobile duration (days)"
													name="designDaysMobile"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('designHoursMobile')) || options.isHome}
												/>
											</Grid>

											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Mobile estimate (billable hours)"
													name="designHoursMobile"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('designDaysMobile')) || options.isHome}
												/>
											</Grid>
										</>
									)}

									{accepts('development') && (
										<>
											<Grid item xs={12}>
												<Typography variant="subtitle2">Development</Typography>
											</Grid>

											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Development duration (days)"
													name="developmentDays"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('developmentHours')) || options.isHome}
												/>
											</Grid>

											<Grid item xs={6}>
												<DataControl
													component={TextField}
													type="number"
													inputProps={{min: 1}}
													label="Development estimate (billable hours)"
													name="developmentHours"
													controller={entry}
													permissionType="edit_estimates"
													required={Boolean(entry.get('developmentDays')) || options.isHome}
												/>
											</Grid>
										</>
									)}
								</Grid>
							</Accordion>
						</Grid>

						<Grid item xs={12}>
							<Accordion title="Notes" defaultCollapsed={!hasNotes}>
								<Stack spacing={3}>
									{accepts('wires') && (
										<DataControl
											component={TextField}
											label="Wires"
											multiline
											minRows={4}
											name="notes.wires"
											controller={entry}
											permissionType="edit_notes"
										/>
									)}

									{accepts('design') && (
										<>
											<DataControl
												component={TextField}
												label="Desktop Design"
												multiline
												minRows={4}
												name="notes.designDesktop"
												controller={entry}
												permissionType="edit_notes"
											/>

											<DataControl
												component={TextField}
												label="Mobile Design"
												multiline
												minRows={4}
												name="notes.designMobile"
												controller={entry}
												permissionType="edit_notes"
											/>
										</>
									)}

									{accepts('development') && (
										<DataControl
											component={TextField}
											label="Development"
											multiline
											minRows={4}
											name="notes.development"
											controller={entry}
											permissionType="edit_notes"
										/>
									)}
								</Stack>
							</Accordion>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>

			<DialogActions sx={{padding: 3}}>
				<Button onClick={handleCancel}>Cancel</Button>
				<Button variant="contained" onClick={handlePrimaryAction}>Save Changes</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EntryDialog;
