import {Box, Chip, IconButton, Paper, Stack, Tooltip, Typography} from '@mui/material';
import {Delete, DescriptionRounded, Edit, Menu, Star} from '@mui/icons-material';

import MenuButton from '../../../components/MenuButton';

const TableItem = ({data, edit, makeDefault, remove, dragHandleProps = false}) => {
	// Calculate summary
	// const totalHours = Object.entries(data).reduce((t, [key, value]) => {
	// 	return ([
	// 		'wiresHours',
	// 		'designHoursDesktop',
	// 		'designHoursMobile',
	// 		'developmentHours',
	// 	].includes(key)) ? t + Number(value) : t;
	// }, 0);
	//
	// const totalCost = calculateCost(totalHours, dataController.get('data.general.hourlyRate'));

	const contextMenuItems = [
		{label: 'Edit', Icon: Edit, action: edit},
		...(!data.isDefault ?
			[
				{label: 'Make Default', Icon: Star, action: makeDefault},
				'-',
				{label: 'Delete', Icon: Delete, action: remove},
			] :
			[]),
	];

	return (
		<Box
			component={Paper}
			gap={2}
			sx={{
				pt: 1.1,
				pb: 0.9,
				px: 2,
				mb: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}>
			<Stack direction="row" spacing={2} alignItems="center">
				{dragHandleProps && (
					<Box {...dragHandleProps} sx={{height: 20}}>
						<Menu sx={{width: 18, height: 18}}/>
					</Box>
				)}

				<Typography variant="body2">{data.title}</Typography>

				{data.isDefault && <Chip size="small" label="Default"/>}
			</Stack>

			<Stack direction="row" alignItems="center" spacing={2}>
				{/*<Stack direction="row" spacing={1}>*/}
				{/*	<Chip size="small" variant="outlined" label={`${totalHours} hours`}/>*/}
				{/*	<Chip size="small" label={totalCost}/>*/}
				{/*</Stack>*/}

				<Stack direction="row">
					{data.description && (
						<IconButton>
							<Tooltip title={<Typography variant="caption">{data.description}</Typography>}>
								<DescriptionRounded sx={{width: 18, height: 18}}/>
							</Tooltip>
						</IconButton>
					)}

					<MenuButton
						items={contextMenuItems}
						iconSx={{width: 18, height: 18}}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default TableItem;
