import {Box, Button, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

const PageHeader = (props) => {
	const {
		title,
		primaryText,
		primaryAction,
		primaryIcon,
		primaryLoading = false,
		CustomLeft = null,
		CustomRight = null,
		secondaryText,
		secondaryAction,
	} = props;

	return (
		<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4}}>
			<Stack spacing={4} direction="row" alignItems="center">
				<Typography variant="h5" color="text.primary" flexShrink={0}>{title}</Typography>

				{CustomLeft}
			</Stack>

			<Stack spacing={4} direction="row" alignItems="center">
				{CustomRight}

				{secondaryText && secondaryAction && (
					<Button onClick={secondaryAction}>{secondaryText}</Button>
				)}

				{primaryText && primaryAction && (
					<LoadingButton
						variant="contained"
						onClick={primaryAction}
						loading={primaryLoading}
						endIcon={primaryIcon}>
						{primaryText}
					</LoadingButton>
				)}
			</Stack>
		</Box>
	);
};

export default PageHeader;
