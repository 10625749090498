import {useEffect, useState} from 'react';

import {Box, Chip, IconButton, Paper, Stack, TextField, Typography} from '@mui/material';

import DataControl from '../../components/DataControl';
import buildCalculateContentPop from '../../providers/calculate-content-pop';
import {Visibility, VisibilityOff} from '@mui/icons-material';

export default function ContentPopArea ({type, title, label, name, helperText, currentValue, dataController}) {
	const [summary, setSummary] = useState({});
	const calculateContentPop = buildCalculateContentPop(dataController);

	useEffect(() => {
		setSummary(() => calculateContentPop(type, currentValue, true));

		if (!dataController.get(`${name}.enabled`)) {
			dataController.update(`${name}.enabled`, true);
		}
	}, [currentValue]);

	// Controls the visibility status.
	const handleToggleVisibility = () => {
		dataController.update(`${name}.enabled`, v => !v);
	};

	const enabled = dataController.get(`${name}.enabled`);

	return (
		<Box component={Paper} elevation={2} sx={{
			padding: 2,
			opacity: enabled ? 1 : 0.5,
		}}>
			<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3}}>
				<Typography variant="h6">{title}</Typography>

				{Object.keys(summary).length > 0 && (
					<Stack direction="row" alignItems="center" spacing={1}>
						<Chip size="small" variant="outlined" label={`${summary.estimate} hours`}/>
						<Chip size="small" label={summary.cost}/>

						<IconButton title="Change Visibility" onClick={handleToggleVisibility}>
							{enabled ? (
								<Visibility sx={{width: 18, height: 18}}/>
							) : (
								<VisibilityOff sx={{width: 18, height: 18}}/>
							)}
						</IconButton>
					</Stack>
				)}
			</Box>

			<Stack spacing={3}>
				<DataControl
					component={TextField}
					type="number"
					inputProps={{min: 0}}
					label={label}
					name={`${name}.value`}
					helperText={helperText}
					controller={dataController}
					permissionType="edit_details"
				/>

				<DataControl
					component={TextField}
					label="Notes"
					multiline
					minRows={4}
					name={`${name}.notes`}
					controller={dataController}
					permissionType="edit_notes"
				/>
			</Stack>
		</Box>
	);
}
