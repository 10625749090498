import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, CircularProgress} from '@mui/material';

import useAuth from '../hooks/useAuth';

const AuthContent = ({children}) => {
    const navigate = useNavigate();
    const {loggedIn, loading} = useAuth();

    useEffect(() => {
        if (!loading && !loggedIn) {
            navigate('/login');
        }
    }, [loggedIn, loading, navigate]);

    return loggedIn ? <>{children}</> : (
        <Box display="flex" justifyContent="center" padding={10}>
            <CircularProgress/>
        </Box>
    );
}

export default AuthContent;
