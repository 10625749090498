import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';

const link = createHttpLink({
    uri: process.env.REACT_APP_API_URI + '/graphql',
    credentials: 'include',
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});
