import apiRequest from '../api-request';

export default async function apiGetProjectDetails(id) {
    try {
        const response = await apiRequest('get_project_details', {id});
        return response.data.status === 'SUCCESS' ? response.data.project : false;
    } catch (e) {
        console.error('There was an error retrieving the project details.', e);
        return false;
    }
}
