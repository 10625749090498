import axios from 'axios';

export default async function apiRequest(action, body = {}) {
    let response = null, status;

    try {
        response = await axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URI + '/wp-admin/admin-ajax.php',
            data: body,
            params: {action},
            withCredentials: true,
        });

        status = 'SUCCESS';

        if (process.env.NODE_ENV === 'development') {
            console.warn(`Action: ${action} |`, response.data);
        }
    } catch (error) {
        console.error(`Action: ${action} | Error:`, error);
        status = 'ERROR';
    }

    return {data: response ? response.data : null, status};
}
