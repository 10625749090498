import {
    Accordion as AccordionWrapper, AccordionActions,
    AccordionDetails,
    AccordionSummary, Box,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = props => {
    const {
        addBox = false,
        title,
        variant,
        defaultCollapsed = false,
        disablePadding = false,
        AdditionalInfo = null,
        children,
        detailsSx,
        ...extraProps
    } = props;

    const Acc = (
        <AccordionWrapper
            variant={variant}
            defaultExpanded={!defaultCollapsed}
            {...extraProps}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    pr: 2,
                }}>
                    <Typography>{title}</Typography>
                    {AdditionalInfo}
                </Box>
            </AccordionSummary>

            <AccordionDetails sx={{p: disablePadding ? 0 : 2, ...detailsSx}}>
                {children}
            </AccordionDetails>
        </AccordionWrapper>
    );

    return addBox ? <Box>{Acc}</Box> : Acc;
};

export default Accordion;
