import apiRequest from '../api-request';

export default async function apiDeleteScenario (project, scenario) {
	try {
		const response = await apiRequest('delete_scenario', {project, scenario});

		return response.data.status === 'SUCCESS' ? response.data.scenariosList : null;
	} catch (e) {
		console.error('There was an error deleting the scenario.', e);

		return false;
	}
}
