import {Route, Routes} from 'react-router-dom';
import {ThemeProvider} from '@mui/material';
import './App.scss';

import {ApolloProvider} from '@apollo/client';

import {createTheme} from '@mui/material/styles';

import AdminHome from './Admin';

import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';

import {client} from './lib/apollo-client';
import {AuthProvider} from './hooks/useAuth';
import useThemePreference from './hooks/useThemePreference';

const themes = {
	light: createTheme({
		palette: {
			mode: 'light',
			primary: {main: '#835500'},
			secondary: {main: '#52643f'},
			background: {default: '#ebe4e2', paper: '#f8f2f4'},
			text: {primary: '#1d1b19'},
		},
		shape: {borderRadius: 4},
		props: {
			MuiTooltip: {arrow: true},
			MuiList: {dense: true},
			MuiMenuItem: {dense: true},
			MuiTable: {size: 'small'},
		},
	}),
	dark: createTheme({
		palette: {
			mode: 'dark',
			primary: {main: '#ab7f49'},
			secondary: {main: '#52643f'},
		},
		shape: {borderRadius: 4},
		props: {
			MuiTooltip: {arrow: true},
			MuiList: {dense: true},
			MuiMenuItem: {dense: true},
			MuiTable: {size: 'small'},
		},
	}),
};

const App = () => {
	const {preferredTheme} = useThemePreference();

	const systemTheme = (
		window.matchMedia &&
		window.matchMedia('(prefers-color-scheme: dark)').matches
	) ? 'dark' : 'light';

	const theme = preferredTheme === 'system' ? systemTheme : preferredTheme;
	document.body.style.backgroundColor = themes[theme].palette.background.default;

	return (
		<ApolloProvider client={client}>
			<AuthProvider>
				<ThemeProvider theme={theme === 'dark' ? themes.dark : themes.light}>
					<div className={`app app--${theme}`}>
						<Routes>
							<Route path="/login" element={<LoginPage/>}/>
							<Route path="/logout" element={<LogoutPage/>}/>
							<Route path="*" element={<AdminHome/>}/>
						</Routes>
					</div>
				</ThemeProvider>
			</AuthProvider>
		</ApolloProvider>
	);
};

export default App;
