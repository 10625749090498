import apiRequest from '../api-request';
import {convertFromDotNotation} from '../parse-dot-notation';

/**
 * @param {object} user
 * @param {boolean} archive
 * @param {object} filters
 * @param {string} term
 * @param {int} page
 * @returns {Promise<boolean|object>}
 */
export default async function apiGetProjects ({
	user,
	archive = false,
	filters = {},
	term = '',
	page = 1,
}) {
	try {
		const response = await apiRequest('get_projects', {
			userId: user.databaseId,
			archive,
			filters,
			term,
			page,
		});

		if (response.data.status !== 'SUCCESS') {
			console.error('There was an error retrieving the projects list.');
			return false;
		}

		return {
			posts: response.data.posts.map(p => ({
				...p.info,
				scenario: p.activeScenario.data,
			})),
			maxPages: response.data.maxPages,
		}
	} catch (e) {
		console.error('There was an error retrieving the projects list.', e);
		return false;
	}
}
