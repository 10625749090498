import apiRequest from '../api-request';

export default async function apiUpdateProjectArchivedStatus(id, archived) {
    try {
        await apiRequest('update_project_archived_status', {id, archived});
        return true;
    } catch (e) {
        console.error("There was an error updating the project's archived status.", e);
        return false;
    }
}