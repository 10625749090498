import sortByDate from '../../providers/sort-by-date';
import isDesignTicket from '../is-design-ticket';

/**
 * Add the "order" property to all tickets.
 *
 * @param {Array} groups
 * @returns {Array}
 */
function applyTicketOrder([...groups]) {
    return groups.map((oldGroup, groupIndex) => {
        const order = groupIndex;

        return [...oldGroup].map(oldTicket => {
            const ticket = {...oldTicket};
            ticket.order = Number(order) + 1;

            if (typeof ticket.subtickets !== 'undefined' && ticket.subtickets.length > 0) {
                const convertedSubtickets = applyTicketOrder(ticket.subtickets);
                const subtickets = [];

                convertedSubtickets.map(subGroup => subGroup.map(subticket => subtickets.push(subticket)));
                ticket.subtickets = subtickets;
            }

            return ticket;
        });
    });
}

/**
 * Check if a date is a holiday.
 *
 * @param {Moment} date
 * @param {Moment[]} holidays
 * @returns {boolean}
 */
function isHoliday(date, holidays) {
    for (const holiday of holidays) {
        if (holiday.format('DD-MM-YYYY') === date.format('DD-MM-YYYY')) {
            return true;
        }
    }

    return false;
}

export default function injectDates(tickets, kickoff, holidays) {
    kickoff = kickoff.clone();

    // Calculate the results
    const convertedTickets = applyTicketOrder([...tickets]);
    const result = [];

    convertedTickets.map(epicGroup => epicGroup.map(epic => result.push({...epic})));

    // * Retrieves the base date
    const getBaseDate = ({ticket, ticketIndex, parent}) => {
        let startAfter;
        let baseDate;

        // ? First epic
        if (!parent && ticketIndex === '0') {
            startAfter = false;
            baseDate = kickoff.clone();
        }

        // ? Other epics
        else if (!parent && ticketIndex !== '0') {
            const lastTicketsInQueue = result.slice(0, ticketIndex)
                .filter(tc => tc.order <= ticket.order);
            const lastTicketInQueue = sortByDate('endDate', lastTicketsInQueue)[0];

            startAfter = lastTicketInQueue.order !== ticket.order;
            baseDate = startAfter ?
                lastTicketInQueue.endDate.clone() :
                lastTicketInQueue.startDate.clone();
        }

        // ? First ticket
        else if (parent && ticketIndex === '0') {
            startAfter = false;
            baseDate = parent.startDate;
        }

        // ? Other tickets
        else if (parent && ticketIndex !== '0') {
            const lastTicketsInQueue = parent.subtickets.slice(0, ticketIndex)
                .filter(tc => tc.order <= ticket.order);
            const lastTicketInQueue = sortByDate('endDate', lastTicketsInQueue)[0];

            // ? Current ticket is part of a design phase
            // = Calculate based on remaining hours
            if (isDesignTicket(parent, ticket)) {
                const availableHours = parent.availableHours;

                baseDate = lastTicketInQueue.endDate.clone();
                startAfter = availableHours <= 0;

                if (parent.availableHours <= 0) parent.availableHours = 8;
            }

            // ? Current ticket is a standard type
            // = Calculate based on order
            else {
                startAfter = lastTicketInQueue.order !== ticket.order;
                baseDate = startAfter ?
                    lastTicketInQueue.endDate.clone() :
                    lastTicketInQueue.startDate.clone();
            }
        }

        return {baseDate, startAfter};
    };

    // * Prepares a ticket
    const prepareTicket = ({ticket, ticketIndex, parent = false, parentIndex = false}) => {
        const {baseDate, startAfter} = getBaseDate({
            ticket,
            ticketIndex,
            parent,
            parentIndex,
        });

        // Set the start date.
        ticket.startDate = baseDate;

        if (startAfter) {
            ticket.startDate.add(1, 'days');
            if (ticket.startDate.day() === 0) ticket.startDate.add(1, 'days');
            if (ticket.startDate.day() === 6) ticket.startDate.add(2, 'days');
        }

        while (isHoliday(ticket.startDate, holidays)) {
            ticket.startDate.add(1, 'days');
        }

        // Prepare subtickets.
        if (typeof ticket.subtickets !== 'undefined') {
            for (const x in ticket.subtickets) {
                ticket.subtickets[x] = prepareTicket({
                    ticket: ticket.subtickets[x],
                    ticketIndex: x,
                    parent: ticket,
                    parentIndex: ticketIndex,
                });
            }
        }

        // Set the end date.
        // ? Based on the subtickets
        if (typeof ticket.subtickets !== 'undefined' && ticket.subtickets.length > 0) {
            const subticketsByDate = sortByDate('endDate', ticket.subtickets);
            ticket.endDate = subticketsByDate[0].endDate.clone();

            // Apply duration
            ticket.duration = 1;

            const currentDate = ticket.startDate.clone();

            while (currentDate.format('DD-MM-YYYY') !== ticket.endDate.format('DD-MM-YYYY')) {
                currentDate.add(1, 'days');
                ticket.duration++;

                while (isHoliday(currentDate, holidays)) {
                    currentDate.add(1, 'days');
                }

                if ([0, 6].includes(currentDate.day())) {
                    ticket.duration--;
                }
            }

            // Apply estimate
            ticket.estimateDisplay = ticket.subtickets
                .reduce((n, t) => n + (t.estimateDisplay ? t.estimateDisplay : t.estimate ?? 0), 0);

            // Add phase lock
            if (ticket.type === 'Epic') {
                ticket.subtickets.push({
                    type: 'Phase Lock',
                    summary: 'Phase Lock',
                    duration: 1,
                    startDate: ticket.endDate.clone(),
                    endDate: ticket.endDate.clone(),
                    hidden: true,
                });
            }
        }

        // ? Based on duration or estimate
        else {
            ticket.endDate = ticket.startDate.clone();
            let endAfter;

            if (isDesignTicket(parent, ticket)) {
                endAfter = ticket.duration;

                // TODO: Old implementation.
                // const availableHours = parent.availableHours ?? 8;
                // const additionalHours = ticket.estimate - availableHours;
                // const additionalDays = additionalHours <= 0 ? 0 : Math.ceil(additionalHours / 8);
                //
                // endAfter = additionalDays;
                // parent.availableHours = additionalDays === 0 ?
                //     availableHours - ticket.estimate :
                //     8 - (additionalHours % 8);
            } else {
                endAfter = (ticket.duration) - 1;
            }

            while (endAfter > 0) {
                ticket.endDate.add(1, 'days');

                while (isHoliday(ticket.endDate, holidays)) {
                    ticket.endDate.add(1, 'days');
                }

                if (![0, 6].includes(ticket.endDate.day())) {
                    endAfter--;
                }
            }
        }

        return ticket;
    };

    // Inject the dates
    for (const epicIndex in result) {
        let epic = result[epicIndex];

        if (typeof epic.subtickets === 'undefined' || epic.subtickets.length === 0) continue;

        epic = prepareTicket({
            ticket: epic,
            ticketIndex: epicIndex,
        });

        result[epicIndex] = epic;
    }

    return result;
}
