import apiRequest from '../api-request';

export default async function apiDeleteProject(id) {
    try {
        await apiRequest('delete_project', {id});
        return true;
    } catch (e) {
        console.error("There was an error deleting the project.", e);
        return false;
    }
}