import {useEffect, useState} from 'react';

import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import apiDeleteProject from '../../providers/api/delete-project';

export default function DeleteDialog ({mRef, project}) {
	const [open, setOpen] = useState(false);
	const [confirmation, setConfirmation] = useState('');
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);

	const methods = {
		open: () => {
			setOpen(true);
			setConfirmation('');
			setError(false);
		},
	};

	useEffect(() => {
		mRef.current = methods;
	}, []);

	const handleChange = ({target: {value}}) => {
		if (error) setError(() => false);
		setConfirmation(() => value);
	};

	const handleCancel = () => onClose();

	const handleArchive = () => {
		if (confirmation === project.title) {
			setLoading(() => true);

			apiDeleteProject(project.id).then(() => window.location.reload());
		} else {
			setError(() => true);
		}
	};

	const onClose = () => {
		setOpen(() => false);
		setConfirmation(() => '');
		setError(() => false);
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Delete Project</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Alert severity="warning">Other users will lose access to this project.</Alert>
					<Typography variant="body1">This will permanently delete
						the <strong>{project.title}</strong> project details,
						content type tickets, and the project will no longer appear on the list.</Typography>
					<Typography variant="body1">Please type <strong>{project.title}</strong> to confirm.</Typography>
					<TextField
						value={confirmation}
						error={error}
						disabled={loading}
						fullWidth
						onChange={handleChange}
					/>
				</Stack>
			</DialogContent>

			<DialogActions>
				<Button autoFocus onClick={handleCancel}>Cancel</Button>
				<Button onClick={handleArchive} disabled={loading}>Delete Project</Button>
			</DialogActions>
		</Dialog>
	);
}