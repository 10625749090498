import calculateCost from './calculate-cost';

const buildCalculateContentPop = dataController => (type, value, includeCost = false) => {
	if (!value) value = 0;

	const estimate = type === 'population' ?
		Math.ceil(value * 1.4) :
		value * 40;

	const duration = type === 'population' ?
		Math.ceil(Math.ceil(value * 1.4) / 8) :
		Math.ceil((value * 40) / 8);

	return {
		estimate,
		duration,
		...(includeCost ? {
			cost: calculateCost(estimate, dataController.get('data.general.hourlyRate')),
		} : {}),
	};
};

export default buildCalculateContentPop;