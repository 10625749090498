import {useRef} from 'react';

import {Chip, Divider, MenuItem, Select, Stack} from '@mui/material';
import ScenarioManager from './ScenarioManager';

const ScenarioController = ({project, update}) => {
	const manager = useRef();

	const handleChange = ({target: {value: selected}}) => {
		if (selected === '__new') return update('__new');
		if (selected === '__manage') return manager.current.open();

		update(selected);
	};

	const scenarios = project.getScenariosList().sort((s1, s2) => {
		if (s1.isDefault) return -1;
		if (s2.isDefault) return 1;

		return 0;
	});

	return scenarios.length === 0 ? null : (
		<>
			<Select
				onChange={handleChange}
				value={project.scenario.get('id')}
				sx={{minWidth: 250}}
				renderValue={() => project.scenario.get('title')}
				size="small">
				{scenarios.map(s => (
					<MenuItem key={s.id} value={s.id} title={s.description}>
						{s.isDefault ? (
							<Stack direction="row" spacing={1}>
								<span>{s.title}</span>
								<Chip label="Default" size="small"/>
							</Stack>
						) : s.title}
					</MenuItem>
				))}

				<Divider/>

				<MenuItem value="__new">Create a new scenario</MenuItem>
				<MenuItem value="__manage">Manage scenarios</MenuItem>
			</Select>

			<ScenarioManager
				mRef={manager}
				project={project}
				scenarios={scenarios}
				create={() => update('__new')}
			/>
		</>
	);
};

export default ScenarioController;
