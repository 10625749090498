function getUserKey(baseKey, controller) {
    let [userType, userKey] = baseKey.split(':');

    if (typeof userKey === 'undefined') {
        if (['projectManager', 'accountExecutive'].includes(userType)) {
            userKey = controller.get(`data.general.${userType}`) ?? false;
        } else {
            userKey = controller.get(`data.general.productionTeam.${userType}`);
        }
    }

    return userKey ?? '';
}

export default function injectAssignees(settings, tickets, controller) {
    for (const ticketIndex in tickets) {
        const ticket = tickets[ticketIndex];

        if (typeof ticket.assignee === 'string') {
            tickets[ticketIndex].skill = ticket.assignee;
            tickets[ticketIndex].assignee = settings.getAssignee(getUserKey(ticket.assignee, controller));
        }

        if (ticket.subtickets.length > 0) {
            tickets[ticketIndex].subtickets = injectAssignees(settings, ticket.subtickets, controller);
        }
    }

    return tickets;
}
