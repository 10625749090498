import buildCalculateContentPop from '../calculate-content-pop';

const createEpics = (settings, controller) => {
	const calculateContentPop = buildCalculateContentPop(controller);

	/**
	 * Calculates a duration based on its weight value.
	 *
	 * @param weight
	 * @returns {number|number}
	 */
	const durationWeight = weight => {
		const duration = Math.ceil(weight * controller.get('data.general.approvalTime'));
		return duration < 1 ? 1 : duration;
	};

	/**
	 * Retrieves data from a scope property.
	 *
	 * @param {string} key
	 * @param {boolean} n
	 * @returns {*}
	 */
	const getScopeData = (key, n = false) => {
		return controller[n ? 'getNumber' : 'get'](`data.scope.${key}`);
	};

	/**
	 * Retrieves data from the additional scope options.
	 *
	 * @param {string} key
	 * @param {boolean} n
	 * @returns {*}
	 */
	const getOption = (key, n = false) => {
		return controller[n ? 'getNumber' : 'get'](`data.scope.additionalOptions.${key}`);
	};

	// Get the modules and integrations
	const modules = getScopeData('modules') ?
		getScopeData('modules').filter(m => typeof m.enabled === 'undefined' || m.enabled) :
		[];

	const integrations = getScopeData('integrations') ?
		getScopeData('integrations').filter(i => typeof i.enabled === 'undefined' || i.enabled) :
		[];

	// Calculate duration and estimate for the UAT fixes ticket
	const developmentHours = [].concat(
		!getOption('initialDevelopment.tickets.initialHandoff.enabled') ? [] : [
			getOption('initialDevelopment.tickets.initialHandoff.estimate', true),
		],
		!getOption('initialDevelopment.tickets.standardNavigation.enabled') ? [] : [
			getOption('initialDevelopment.tickets.standardNavigation.estimate', true),
		],
		!getOption('initialDevelopment.tickets.globalSetup.enabled') ? [] : [
			getOption('initialDevelopment.tickets.globalSetup.estimate', true),
		],
		[getScopeData('home.developmentHours', true)],
		modules
			.filter(m => Boolean(m.developmentDays) && Boolean(m.developmentHours))
			.map(m => Number(m.developmentHours) ?? 0),
		integrations
			.filter(i => Boolean(i.developmentDays) && Boolean(i.developmentHours))
			.map(i => Number(i.developmentHours) ?? 0),
	).reduce((t, n) => t + n);

	const uatFixesEstimate = Math.ceil((developmentHours * 13) / 100);
	const uatFixesDuration = Math.ceil(uatFixesEstimate / 8);

	// Get the content population/migration statuses
	let contentPopEnabled = getScopeData('contentPop.enabled');
	let contentMigEnabled = getScopeData('contentMig.enabled');

	if (typeof contentPopEnabled === 'undefined') contentPopEnabled = true;
	if (typeof contentMigEnabled === 'undefined') contentMigEnabled = true;

	// Prepare the global setup description.
	const globalSetupDescription = [
		'This development phase includes general configuration for:',
		'- Project setup (e.g. Pantheon instance, Git repository, WordPress instance, etc.)',
		'- SCSS Variables (e.g. colors, font families, etc.)',
		'- Global elements (e.g. buttons)',
		'- Any other generic configuration',
	];

	const globalSetupNotes = getOption('initialDevelopment.tickets.globalSetup.notes');
	if (globalSetupNotes) globalSetupDescription.push(
		'---------------',
		globalSetupNotes,
	);

	const epics = [
		{
			// Setup
			type: 'Epic',
			summary: 'Setup',
			subtickets: [[
				{type: 'Internal', summary: 'Kickoff', assignee: 'projectManager', duration: 1, estimate: 1},
				{type: 'Internal', summary: 'Project Setup', assignee: 'projectManager', duration: 3, estimate: 3},
			]],
		},
		{
			// Strategy
			type: 'Epic',
			summary: 'Strategy',
			subtickets: [
				...(getOption('strategy.tickets.digitalStrategy.enabled') ? [
					[{
						type: 'Strategy',
						summary: 'Digital Strategy: BSA',
						description: getOption('strategy.tickets.digitalStrategy.notes') ?? '',
						assignee: 'accountExecutive',
						duration: getOption('strategy.tickets.digitalStrategy.duration', true),
						estimate: getOption('strategy.tickets.digitalStrategy.estimate', true),
					}, {
						type: 'Client Task',
						summary: 'Digital Strategy: Client',
						assignee: 'projectManager',
						duration: durationWeight(1),
					}],
					[{
						type: 'Client Task',
						summary: 'Approval: Digital Strategy',
						assignee: 'projectManager',
						duration: durationWeight(1),
					}],
				] : []),

				...[[
					// Information Architecture
					getOption('strategy.tickets.informationArchitecture.enabled') ? {
						type: 'Strategy',
						summary: 'Information Architecture',
						description: getOption('strategy.tickets.informationArchitecture.notes') ?? '',
						assignee: 'uxStrategist',
						duration: getOption('strategy.tickets.informationArchitecture.duration', true),
						estimate: getOption('strategy.tickets.informationArchitecture.estimate', true),
					} : false,

					// Content Strategy
					getOption('strategy.tickets.contentStrategy.enabled') ? {
						type: 'Strategy',
						summary: 'Content Strategy',
						description: getOption('strategy.tickets.contentStrategy.notes') ?? '',
						assignee: 'uxStrategist',
						duration: getOption('strategy.tickets.contentStrategy.duration', true),
						estimate: getOption('strategy.tickets.contentStrategy.estimate', true),
					} : false,
				].filter(i => i)],

				// Approval
				getOption('strategy.tickets.informationArchitecture.enabled') ||
				getOption('strategy.tickets.contentStrategy.enabled') ? [{
					type: 'Client Task',
					summary: 'Approval: IA / Content Strategy',
					assignee: 'projectManager',
					duration: durationWeight(1),
				}] : false,
			],
		},
		{
			// Moodboards
			type: 'Epic',
			summary: 'Moodboards',
			subtickets: [
				...(getOption('moodboards.tickets.moodboards.enabled') ? [
					[{
						type: 'Design',
						summary: 'Moodboards',
						description: getOption('moodboards.tickets.moodboards.notes') ?? '',
						assignee: 'seniorDesigner',
						duration: getOption('moodboards.tickets.moodboards.duration', true),
						estimate: getOption('moodboards.tickets.moodboards.estimate', true),
					}],
					[{
						type: 'Client Task',
						summary: 'Approval: Moodboards',
						assignee: 'projectManager',
						duration: durationWeight(0.2),
					}],
				] : []),
			],
		},
		{
			// Wires
			type: 'Epic',
			summary: 'Wires',
			subtickets: [
				[{
					type: 'Design',
					summary: 'Homepage: Wires',
					description: getScopeData('home.notes.wires'),
					assignee: 'juniorDesigner',
					duration: getScopeData('home.wiresDays', true),
					estimate: getScopeData('home.wiresHours', true),
				}],

				// Inners
				...(modules
						.filter(m => Boolean(m.wiresDays) && Boolean(m.wiresHours))
						.map(m => ([{
							type: 'Design',
							internalName: m.title,
							summary: `${m.title}: Wires`,
							description: m.notes?.wires ?? '',
							assignee: 'juniorDesigner',
							duration: Number(m.wiresDays),
							estimate: Number(m.wiresHours),
						}]))
				),

				// Approval Ticket
				modules.filter(
					m => m.type !== 'feature' && m.wiresDays && m.wiresHours,
				).length > 0 ? [{
					type: 'Client Task',
					summary: 'Approval: Wires',
					assignee: 'projectManager',
					duration: durationWeight(1),
				}] : [],
			],
		},
		{
			// Initial Design
			type: 'Epic',
			summary: 'Initial Design',
			subtickets: [
				[{
					type: 'Design',
					summary: 'UI: Homepage - Desktop',
					assignee: 'seniorDesigner',
					duration: getScopeData('home.designDaysDesktop', true),
					estimate: getScopeData('home.designHoursDesktop', true),
				}],
				[{
					type: 'Internal',
					summary: 'Approval: Initial Design (Internal)',
					assignee: 'User:dave',
					duration: 3,
				}],
				[{
					type: 'Client Task',
					summary: 'Approval: Initial Design (Client)',
					assignee: 'projectManager',
					duration: durationWeight(1),
				}],
			],
		},
		{
			// Design: Desktop
			type: 'Epic',
			subtype: 'inner',
			summary: 'Inner Design - Desktop',
			subtickets: [
				...(modules
						.filter(m => Boolean(m.designDaysDesktop) && Boolean(m.designHoursDesktop))
						.map(m => ([{
							type: 'Design',
							internalName: m.title,
							summary: `${m.title}: Desktop`,
							description: m.notes?.designDesktop ?? '',
							assignee: 'juniorDesigner',
							duration: Number(m.designDaysDesktop),
							estimate: Number(m.designHoursDesktop),
						}]))
				),
			],
		},
		{
			// Design: Approvals
			type: 'Epic',
			summary: 'Inner Design - Approvals',
			subtickets: [
				modules
					.filter(m => (
						(Boolean(m.designDaysDesktop) && Boolean(m.designHoursDesktop)) ||
						(Boolean(m.designDaysMobile) && Boolean(m.designHoursMobile))
					))
					.map(m => ({
						type: 'Client Task',
						summary: `Approval: ${m.title}`,
						assignee: 'projectManager',
						duration: durationWeight(1),
					})),
			],
		},
		{
			// Design: Mobile
			type: 'Epic',
			subtype: 'inner',
			summary: 'Inner Design - Mobile',
			subtickets: [
				[{
					type: 'Design',
					internalName: 'Homepage',
					summary: `Homepage: Mobile`,
					description: getScopeData('home.notes.designMobile'),
					assignee: 'juniorDesigner',
					duration: getScopeData('home.designDaysMobile', true),
					estimate: getScopeData('home.designHoursMobile', true),
				}],

				...(modules
						.filter(m => Boolean(m.designDaysMobile) && Boolean(m.designHoursMobile))
						.map(m => ([{
							type: 'Design',
							internalName: m.title,
							summary: `${m.title}: Mobile`,
							description: m.notes?.designMobile ?? '',
							assignee: 'juniorDesigner',
							duration: Number(m.designDaysMobile),
							estimate: Number(m.designHoursMobile),
						}]))
				),
			],
		},
		{
			// Initial Development
			type: 'Epic',
			summary: 'Initial Development',
			subtickets: [
				...(getOption('initialDevelopment.tickets.initialHandoff.enabled') ? [
					[{
						type: 'Internal',
						summary: 'UI: Initial Handoff',
						description: getOption('initialDevelopment.tickets.initialHandoff.notes') ?? '',
						assignee: 'seniorDesigner',
						duration: getOption('initialDevelopment.tickets.initialHandoff.duration', true),
						estimate: getOption('initialDevelopment.tickets.initialHandoff.estimate', true),
					}],
				] : []),

				...[[
					// Global Setup
					getOption('initialDevelopment.tickets.globalSetup.enabled') ? {
						type: 'Development',
						summary: 'Global Setup',
						description: globalSetupDescription.join('\n'),
						assignee: 'seniorDeveloper',
						duration: getOption('initialDevelopment.tickets.globalSetup.duration', true),
						estimate: getOption('initialDevelopment.tickets.globalSetup.estimate', true),
					} : false,

					// Standard Navigation
					getOption('initialDevelopment.tickets.standardNavigation.enabled') ? {
						type: 'Development',
						summary: 'Standard Navigation / Footer',
						description: getOption('initialDevelopment.tickets.standardNavigation.notes') ?? '',
						assignee: 'seniorDeveloper',
						duration: getOption('initialDevelopment.tickets.standardNavigation.duration', true),
						estimate: getOption('initialDevelopment.tickets.standardNavigation.estimate', true),
					} : false,
				].filter(i => i)],
			],
		},
		{
			// Inner Development
			type: 'Epic',
			summary: 'Inner Development',
			subtickets: [
				// Home
				[{
					type: 'Page Dev',
					internalType: 'page-dev',
					internalName: 'Home',
					summary: 'Home',
					description: getScopeData('home.notes.development') ?? '',
					assignee: 'seniorDeveloper',
					duration: getScopeData('home.developmentDays', true),
					estimate: getScopeData('home.developmentHours', true),
				}],

				// Modules
				...(modules
						.filter(m => Boolean(m.developmentDays) && Boolean(m.developmentHours))
						.map(m => ([{
							type: m.type === 'feature' ? 'Development' : 'Page Dev',
							internalType: `${m.type}-dev`,
							internalName: m.title,
							summary: m.type === 'feature' ? `Feature: ${m.title}` : m.title,
							description: m.notes?.development ?? '',
							assignee: 'juniorDeveloper',
							duration: Number(m.developmentDays),
							estimate: Number(m.developmentHours),
						}]))
				),

				// Integrations
				integrations.length > 0 ? [{
					type: 'Development',
					summary: 'Integrations',
					assignee: 'juniorDeveloper',
					subtickets: [
						integrations
							.filter(i => Boolean(i.developmentDays) && Boolean(i.developmentHours))
							.map(i => ({
								type: 'Sub-task',
								summary: i.title,
								description: i.notes?.development ?? '',
								duration: Number(i.developmentDays),
								estimate: Number(i.developmentHours),
							})),
					],
				}] : false,
			],
		},
		{
			// Content Collection
			type: 'Epic',
			summary: 'Content Collection',
			subtickets: [
				[{type: 'Client Task', summary: 'Content Collection', assignee: 'projectManager', duration: 5}],
			],
		},
		{
			// UAT
			type: 'Epic',
			summary: 'UAT',
			subtickets: [
				[{
					type: 'Internal',
					summary: 'Internal UAT: Handoff Preparation',
					assignee: 'projectManager',
					subtickets: [
						[
							{
								type: 'Sub-task',
								summary: 'Design UAT',
								assignee: 'seniorDesigner',
								duration: 1,
								estimate: 2,
							},
							{
								type: 'Sub-task',
								summary: 'Final Team Call',
								assignee: 'projectManager',
								duration: 1,
								estimate: 1,
							},
						],
					],
				}],
				[{
					type: 'Internal',
					summary: 'Client Handoff',
					assignee: 'User:alex',
					duration: 1,
					estimate: 1,
				}],

				getScopeData('contentPop.value', true) && contentPopEnabled ? [{
					type: 'Internal',
					summary: 'Content Population',
					description: getScopeData('contentPop.notes'),
					assignee: 'seniorDeveloper',
					...(calculateContentPop('population', getScopeData('contentPop.value', true))),
				}] : false,

				getScopeData('contentMig.value', true) && contentMigEnabled ? [{
					type: 'Internal',
					summary: 'Content Migration',
					description: getScopeData('contentMig.notes'),
					assignee: 'seniorDeveloper',
					...(calculateContentPop('migration', getScopeData('contentMig.value', true))),
				}] : false,

				[{
					type: 'Client Task',
					summary: 'Client UAT',
					assignee: 'projectManager',
					duration: durationWeight(1),
				}],
				[{
					type: 'Development',
					summary: 'Client UAT - Fixes',
					assignee: 'juniorDeveloper',
					duration: uatFixesDuration,
					estimate: uatFixesEstimate,
				}],
				[{
					type: 'Client Task',
					summary: 'Approval: Final',
					assignee: 'projectManager',
					duration: durationWeight(1),
				}],
			],
		},
		{
			// Launch
			type: 'Epic',
			summary: 'Launch',
			subtickets: [
				...[[
					// Pre-Launch Checklist
					getOption('launch.tickets.preLaunchChecklist.enabled') ? {
						type: 'Internal',
						summary: 'Pre-Launch Checklist',
						description: getOption('launch.tickets.preLaunchChecklist.notes') ?? '',
						assignee: 'seniorDeveloper',
						duration: getOption('launch.tickets.preLaunchChecklist.duration', true),
						estimate: getOption('launch.tickets.preLaunchChecklist.estimate', true),
					} : false,

					// URL Redirection
					getOption('launch.tickets.urlRedirection.enabled') ? {
						type: 'Internal',
						summary: 'URL Redirection',
						description: getOption('launch.tickets.urlRedirection.notes') ?? '',
						assignee: 'seniorDeveloper',
						duration: getOption('launch.tickets.urlRedirection.duration', true),
						estimate: getOption('launch.tickets.urlRedirection.estimate', true),
					} : false,
				].filter(i => i)],

				// Launch
				[{
					type: 'Internal',
					summary: 'Launch',
					assignee: 'seniorDeveloper',
					duration: 1,
					estimate: 4,
				}],
			],
		},
	];

	return epics
		.map(epic => ({...epic, subtickets: epic.subtickets.filter(i => i && i.length > 0)}))
		.filter(epic => epic.subtickets.length > 0)
		.map(epic => ([epic]));
};

export default createEpics;