import {Route, Routes} from 'react-router-dom';

import {AppSettingsProvider} from './hooks/useAppSettings';

import AuthContent from './components/AuthContent';

import DashboardHome from './Dashboard';
import MaintenancePage from './pages/Maintenance';

const Admin = () => {
	return (
		<AuthContent>
			<AppSettingsProvider>
				<Routes>
					<Route path="/maintenance" element={<MaintenancePage/>}/>
					<Route path="*" element={<DashboardHome/>}/>
				</Routes>
			</AppSettingsProvider>
		</AuthContent>
	);
};

export default Admin;
