import {useEffect, useState} from 'react';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';

import apiUpdateProjectStatus from '../../providers/api/update-project-status';

import statusList from '../../data/project-status-list';

export default function StatusDialog({mRef, project, onUpdate}) {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(project.status);

    const methods = {open: () => setOpen(true)};

    useEffect(() => {
        mRef.current = methods;
    }, []);

    const handleChange = ({target: {value: status}}) => {
        onClose();
        setCurrent(() => status);
        apiUpdateProjectStatus(project.id, status).then(() => onUpdate(status));
    };

    const onClose = () => {
        setOpen(() => false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Select a new status</DialogTitle>

            <DialogContent dividers sx={{width: 280, maxWidth: '100%'}}>
                <RadioGroup
                    value={current}
                    onChange={handleChange}>
                    {statusList.map(option => (
                        <FormControlLabel
                            value={option}
                            key={option}
                            control={<Radio/>}
                            label={option}
                        />
                    ))}
                </RadioGroup>
            </DialogContent>
        </Dialog>
    );
}