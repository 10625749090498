import sortByDate from './sort-by-date';
import calculateCost from './calculate-cost';

const buildGetEpic = tickets => name => {
    const [epic] = tickets.filter(e => e.summary === name);
    return epic ?? null;
}

export default function calculateProjectSummary(tickets, pmHours, controller) {
    const getEpic = buildGetEpic(tickets);
    const hourlyRate = controller.get('data.general.hourlyRate');

    const summaryRef = [
        {
            title: 'Setup',
            makeOf: ['Setup']
        },
        {
            title: 'Strategy',
            makeOf: ['Strategy'],
        },
        {
            title: 'Design',
            makeOf: [
                'Moodboards',
                'Wires',
                'Initial Design',
                'Inner Design - Desktop',
                'Inner Design - Approvals',
                'Inner Design - Mobile',
            ],
        },
        {
            title: 'Development',
            makeOf: [
                'Initial Development',
                'Inner Development',
            ],
        },
        {
            title: 'UAT',
            makeOf: ['UAT'],
        },
        {
            title: 'Launch',
            makeOf: ['Launch'],
        },
    ];

    const summary = summaryRef.reduce((summary, phase) => {
        const epics = phase.makeOf.map(getEpic).filter(e => e);
        const estimate = epics.reduce((t, e) => t + e.estimateDisplay, 0);

        return epics.length === 0 ? summary : [...summary, {
            title: phase.title,
            estimate,
            duration: epics.reduce((t, e) => t + e.duration ?? 0, 0),
            cost: calculateCost(estimate, hourlyRate),
            startDate: sortByDate('startDate', [...epics])[0].startDate,
            endDate: sortByDate('endDate', [...epics])[0].endDate,
        }];
    }, []);

    const totalEstimate = summary.reduce((t, p) => t + p.estimate, 0);

    // Calculate the totals for phases
    const productionTotals = {
        duration: summary.reduce((t, p) => t + p.duration, 0),
        estimate: totalEstimate,
        cost: calculateCost(totalEstimate, hourlyRate),
        startDate: summary[0].startDate,
        endDate: summary[summary.length - 1].endDate,
    }

    const managementTotals = {
        duration: Math.ceil(pmHours / 8),
        estimate: pmHours,
        cost: calculateCost(pmHours, hourlyRate),
    }

    const totals = {
        duration: productionTotals.duration + managementTotals.duration,
        estimate: productionTotals.estimate + managementTotals.estimate,
        cost: calculateCost(productionTotals.estimate + managementTotals.estimate, hourlyRate),
        startDate: productionTotals.startDate,
        endDate: productionTotals.endDate,
    }

    return {
        phases: summary,
        totals: {
            production: productionTotals,
            management: managementTotals,
            sum: totals,
        }
    }
}
