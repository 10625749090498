import {useCallback, useEffect, useRef, useState} from 'react';

/**
 * Needed because data sometimes doesn't update fast enough.
 *
 * @param initial
 * @returns {[unknown,function]}
 */
const useStateCallback = initial => {
	const [state, setState] = useState(initial);
	const cbRef = useRef(null); // init mutable ref container for callbacks

	const setStateCallback = useCallback((state, cb) => {
		cbRef.current = cb;
		setState(state);
	}, []);

	useEffect(() => {
		if (cbRef.current) {
			cbRef.current(state);
			cbRef.current = null;
		}
	}, [state]);

	return [state, setStateCallback];
};

export default useStateCallback;
