import apiRequest from '../api-request';

export default async function apiUpdateProject(project, results, timestamp) {
    try {
        const scenario = project.scenario.getData();
        scenario.data.budget = results.budget;
        scenario.data.launch = results.launch.format('YYYYMMDD');

        await apiRequest('update_project', {
            data: project.data.getData(),
            scenario,
            timestamp,
        });

        return true;
    } catch (e) {
        console.error('There was an error updating the project.', e);
        return false;
    }
}
