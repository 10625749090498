import {createContext, useContext} from 'react';
import {useQuery, gql} from '@apollo/client';

const DEFAULT_STATE = {
    loggedIn: false,
    user: undefined,
    loading: false,
    error: undefined,
}

const AuthContext = createContext(DEFAULT_STATE);

export const GET_USER = gql`
    query getUser {
        viewer {
            id
            databaseId
            email
            capabilities
            name
            roles {
                edges {
                    node {
                        name
                    }
                }
            }
        }
    }
`;

const userCan = capabilities => actions => {
    if (typeof actions === 'string') actions = [actions];
    return capabilities.filter(cap => actions.includes(cap.replace('bsa_', ''))).length === actions.length;
}

const AuthProvider = ({children}) => {
    const {data, loading, error} = useQuery(GET_USER);
    const userData = data?.viewer;
    const loggedIn = Boolean(userData);

    const user = loggedIn ? {
        ...userData,
        role: userData.roles.edges[0].node.name,
        can: userCan(userData.capabilities),
    } : false;

    const value = {
        loggedIn,
        user,
        loading,
        error,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext);

export {AuthProvider};
export default useAuth;