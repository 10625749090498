import useAppSettings from '../hooks/useAppSettings';
import {useNavigate} from 'react-router-dom';
import {Box, Chip, Stack, Typography} from '@mui/material';
import {useEffect} from 'react';

const Maintenance = () => {
	const navigate = useNavigate();
	const settings = useAppSettings();

	useEffect(() => {
		if (
			typeof settings.maintenance === 'undefined' ||
			!settings.maintenance.active
		) navigate('/');
	}, [settings]);

	return !settings.maintenance.active ? null : (
		<Stack sx={{maxWidth: 350, my: 10, mx: 'auto'}} textAlign="center" alignItems="center" spacing={2}>
			<Box>
				<Typography color="white">Scheduled maintenance active.</Typography>
				<Typography color="white">This downtime will last until:</Typography>
			</Box>
			<Chip label={settings.maintenance.end.format('MMMM DD, h:mm a')}/>
		</Stack>
	);
}

export default Maintenance;