function inject(tickets, ID = 1) {
    const result = [...tickets].map(oldTicket => {
        const ticket = {
            ...oldTicket,
            ID: ID++,
            typeSlug: `${oldTicket.type}`.replace(/[^\w\s]/g, '').replace(' ', '-').toLowerCase(),
            subtickets: [],
        };

        if (typeof oldTicket.subtickets !== 'undefined' && oldTicket.subtickets.length > 0) {
            const result = inject([...oldTicket.subtickets], ID);

            ID = result.ID;
            ticket.subtickets = result.tickets;
        }

        return ticket;
    });

    return {tickets: result, ID};
}

export default function injectIDs(epics) {
    return inject(epics).tickets;
}
