import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation, gql} from '@apollo/client';

import {Alert, Box, Typography} from '@mui/material';

import {GET_USER} from '../hooks/useAuth';

const LOG_OUT = gql`
    mutation logOut {
        logout(input: {}) {
            status
        }
    }
`;

const Logout = () => {
    const navigate = useNavigate();
    const [logout, {called, loading, error, data}] = useMutation(LOG_OUT, {
        refetchQueries: [{query: GET_USER}],
    });

    const loggedOut = Boolean(data?.logout?.status);

    useEffect(() => {
        logout().then(() => setTimeout(() => navigate('/login'), 3000));
    }, [logout]);

    return (
        <Box sx={{maxWidth: 350, my: 10, mx: 'auto'}}>
            {!called || loading ? (
                <Typography color="white">Logging out...</Typography>
            ) : error ? (
                <Alert severity="error">{error.message}</Alert>
            ) : !loggedOut ? (
                <Alert severity="error">Unable to log out. Please reload the page and try again.</Alert>
            ) : (
                <Typography color="white">You have been logged out. Redirecting...</Typography>
            )}
        </Box>
    );
};

export default Logout;
