import {useEffect, useState} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

const INITIAL_OPTIONS = {
	title: '',
	text: 'Are you sure?',
	cancelText: 'No',
	confirmText: 'Yes',
	onCancel: () => {},
	onConfirm: () => {},
}

const ConfirmDialog = ({mRef}) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState(INITIAL_OPTIONS);

	const methods = {
		open: opts => {
			setOptions({...INITIAL_OPTIONS, ...opts});
			setOpen(true);
		},
	};

	useEffect(() => {
		mRef.current = methods;
	}, []);

	const handleCancel = () => {
		options.onCancel();
		setOpen(false);
	}

	const handleConfirm = () => {
		options.onConfirm();
		setOpen(false);
	}

	return !open ? null : (
		<Dialog open={open} onClose={handleCancel}>
			{options.title && <DialogTitle>{options.title}</DialogTitle>}
			<DialogContent>
				{typeof options.text === 'string' ? (
					<DialogContentText>{options.text}</DialogContentText>
				) : options.text.map((line, key) => (
					<DialogContentText key={key}>{line}</DialogContentText>
				))}
			</DialogContent>
			<DialogActions sx={{padding: 3}}>
				<Button onClick={handleCancel}>{options.cancelText}</Button>
				<Button variant="contained" onClick={handleConfirm}>{options.confirmText}</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDialog;