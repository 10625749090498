import {range} from 'lodash/util';

import {Box, Grid, MenuItem, Select, Stack, TextField} from '@mui/material';

import useAppSettings from '../../../hooks/useAppSettings';

import DataControl from '../../../components/DataControl';
import DataControlDate from '../../../components/DataControlDate';

import ProductionTeamArea from '../ProductionTeamArea';

const Details = ({project}) => {
    const settings = useAppSettings();

    return (
        <Stack spacing={4}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <DataControl
                        component={Select}
                        label="Project Type"
                        name="data.type"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required>
                        <MenuItem value="marketing">Marketing</MenuItem>
                        <MenuItem value="magento">Magento</MenuItem>
                        <MenuItem value="woocommerce">WooCommerce</MenuItem>
                    </DataControl>
                </Grid>

                <Grid item xs={3}>
                    <DataControl
                        component={Select}
                        label="PM"
                        name="data.general.projectManager"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required>
                        {settings.assignees.projectManagers.map((user, userKey) => (
                            <MenuItem key={userKey} value={user.value}>{user.label}</MenuItem>
                        ))}
                    </DataControl>
                </Grid>

                <Grid item xs={3}>
                    <DataControl
                        component={Select}
                        label="AE"
                        name="data.general.accountExecutive"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required>
                        {settings.assignees.accountExecutives.map((user, userKey) => (
                            <MenuItem key={userKey} value={user.value}>{user.label}</MenuItem>
                        ))}
                    </DataControl>
                </Grid>

                <Grid item xs={3}>
                    <DataControlDate
                        label="Kickoff Date"
                        name="data.kickoff"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required
                    />
                </Grid>

                <Grid item xs={4}>
                    <DataControl
                        component={TextField}
                        type="number"
                        inputProps={{min: 0}}
                        label="Hourly Rate"
                        name="data.general.hourlyRate"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required
                    />
                </Grid>

                <Grid item xs={4}>
                    <DataControl
                        component={TextField}
                        type="number"
                        inputProps={{min: 0}}
                        label="PM Hours / Week"
                        name="data.general.managementTime"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required
                    />
                </Grid>

                <Grid item xs={4}>
                    <DataControl
                        component={Select}
                        label="Client Approval Time"
                        name="data.general.approvalTime"
                        controller={project.scenario}
                        permissionType="edit_details"
                        required>
                        {range(1, 11, 1).map((n, nKey) => (
                            <MenuItem key={nKey} value={n}>{n} {n === 1 ? 'day' : 'days'}</MenuItem>
                        ))}
                    </DataControl>
                </Grid>

                <Grid item xs={12}>
                    <DataControl
                        component={TextField}
                        multiline
                        minRows={6}
                        label="Project Notes"
                        name="data.general.notes"
                        controller={project.scenario}
                        permissionType="edit_notes"
                    />
                </Grid>
            </Grid>

            <Box>
                <ProductionTeamArea
                    dataController={project.scenario}
                    options={settings.assignees}
                />
            </Box>
        </Stack>
    );
}

export default Details;
