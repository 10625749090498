import {useState} from 'react';

import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import {DescriptionRounded} from '@mui/icons-material';

import calculateCost from '../../providers/calculate-cost';

import DataControl from '../../components/DataControl';
import Accordion from '../../components/Accordion';

const DataTypeItem = ({name, data, dataController, isLast}) => {
	const [notesOpen, setNotesOpen] = useState(false);

	const openNotes = () => setNotesOpen(true);
	const closeNotes = () => setNotesOpen(false);

	const notes = dataController.get(`${name}.notes`);

	return (
		<Box>
			<Dialog open={notesOpen} maxWidth="sm" fullWidth onClose={closeNotes}>
				<DialogTitle>Edit Notes</DialogTitle>

				<DialogContent>
					<Box pt={2}>
						<DataControl
							component={TextField}
							label="Notes"
							multiline
							minRows={4}
							name={`${name}.notes`}
							controller={dataController}
							permissionType="edit_notes"
						/>
					</Box>
				</DialogContent>

				<DialogActions sx={{padding: 3}}>
					<Button onClick={closeNotes}>Close</Button>
				</DialogActions>
			</Dialog>

			<Stack direction="row" justifyContent="space-between" p={2}>
				<Stack direction="row" alignItems="center" spacing={2}>
					<DataControl
						component="switch"
						size="small"
						label="Hide Ticket?"
						name={`${name}.enabled`}
						controller={dataController}
						permissionType="edit_details"
					/>

					<Typography variant="body2">{data.label}</Typography>
				</Stack>

				<Stack direction="row" alignItems="center" spacing={2}>
					<DataControl
						component={TextField}
						type="number"
						inputProps={{min: 1}}
						label="Duration"
						name={`${name}.duration`}
						controller={dataController}
						permissionType="edit_estimates"
						required={data.enabled}
						size="small"
						sx={{width: 180, opacity: data.enabled ? 1 : 0.3}}
					/>

					<DataControl
						component={TextField}
						type="number"
						inputProps={{min: 1}}
						label="Estimate"
						name={`${name}.estimate`}
						controller={dataController}
						permissionType="edit_estimates"
						required={data.enabled}
						size="small"
						sx={{width: 180, opacity: data.enabled ? 1 : 0.3}}
					/>

					<IconButton
						onClick={openNotes}
						sx={{opacity: notes ? 1 : 0.3}}>
						{notes ? (
							<Tooltip  title={
								<Typography variant="caption">
									{notes.trim().split('\n').map((l, x) => <span key={x}>{l}<br /></span>)}
								</Typography>
							}>
								<DescriptionRounded sx={{width: 18, height: 18}}/>
							</Tooltip>
						) : (
							<DescriptionRounded sx={{width: 18, height: 18}}/>
						)}
					</IconButton>
				</Stack>
			</Stack>

			{!isLast && <Divider />}
		</Box>
	)
}

const DataTypeArea = ({name, data, dataController}) => {
	const baseName = `data.scope.additionalOptions.${name}.tickets`;

	const totalHours = Object.values(data.tickets).reduce((t, tc) => t + (tc.enabled ? (Number(tc.estimate) ?? 0) : 0), 0);
	const totalCost = calculateCost(totalHours, dataController.get('data.general.hourlyRate'));

	return (
		<Box>
			<Accordion
				title={data.label}
				elevation={2}
				defaultCollapsed
				disablePadding
				AdditionalInfo={(
					<Stack direction="row" spacing={1}>
						<Chip size="small" variant="outlined" label={`${totalHours} hours`}/>
						<Chip size="small" label={totalCost}/>
					</Stack>
				)}>

				<Box>
					{Object.entries(data.tickets).map(([ticketName, ticket], i) => (
						<DataTypeItem
							key={i}
							name={`${baseName}.${ticketName}`}
							data={ticket}
							dataController={dataController}
							isLast={i === Object.values(data.tickets).length - 1}
						/>
					))}
				</Box>
			</Accordion>
		</Box>
	);
};

export default function AdditionalOptionsArea ({controller}) {
	return (
		<div>
			<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
				<Typography variant="h6">Additional Options</Typography>
			</Box>

			<Stack spacing={2}>
				{Object.entries(controller.get('data.scope.additionalOptions')).map(([typeKey, typeData]) => (
					<DataTypeArea
						key={typeKey}
						name={typeKey}
						data={typeData}
						dataController={controller}
					/>
				))}
			</Stack>
		</div>
	);
}
