import {createContext, useState} from 'react';
import m from 'moment';

import useAuth from './useAuth';
import useStateCallback from './useStateCallback';

import {
	getFromDotNotation,
	setFromDotNotation,
} from '../providers/parse-dot-notation';

const useData = (initial = {}) => {
	const [data, setData] = useStateCallback(initial);
	const [dataStatus, setDataStatus] = useState('ok');

	const [invalid, setInvalid] = useState([]);
	const [required, setRequired] = useState({});

	const [history, setHistory] = useState([]);
	const [listeners, setListeners] = useState({});

	const {user} = useAuth();

	return createContext({
		onUpdate,
		addRequired,
		removeRequired,
		get,
		getData,
		getNumber,
		getHistory,
		isEditable,
		isInvalid,
		reset,
		status,
		update,
		validate,
	});

	function _addHistory (key, newValue, oldValue) {
		setHistory(h => [...h, {
			key,
			newValue,
			oldValue,
			time: m().format('X'),
		}]);
	}

	function _onUpdate (key, newValue, oldValue) {
		if (typeof listeners['__all'] !== 'undefined') {
			listeners['__all'].map(cb => cb(key, newValue, oldValue));
		}

		if (typeof listeners[key] === 'undefined') return;

		listeners[key].map(cb => cb(newValue, oldValue));
	}

	function addRequired (name, field) {
		field = field.node ?? field;
		setRequired(r => ({...r, [name]: field}));
	}

	function removeRequired (name) {
		setRequired(r => {
			const n = {...r};

			if (typeof n[name] !== 'undefined') {
				delete n[name];
			}

			return n;
		});
	}

	function get (key, format = 'default') {
		const value = getFromDotNotation(data, key);

		switch (format) {
			case 'date':
				return m(value, 'YYYYMMDD');
			case 'number':
				return Number(value) ?? 0;
			default:
				return value;
		}
	}

	function getData () {
		return data;
	}

	function getNumber (key) {
		return get(key, 'number');
	}

	function getHistory () {
		return history;
	}

	function isEditable (permissionType) {
		return permissionType === 'free' || user.can(permissionType);
	}

	function isInvalid (key) {
		return typeof required[key] !== 'undefined' && required[key] && invalid.includes(key);
	}

	function onUpdate (key, cb) {
		setListeners(l => {
			const n = {...l};

			if (typeof n[key] === 'undefined') n[key] = [];
			n[key].push(cb);

			return n;
		});
	}

	function reset (newData) {
		setData(newData);
		setInvalid([]);
		setDataStatus('ok');
	}

	function status (newStatus = '') {
		if (newStatus) {
			setDataStatus(newStatus);
			return newStatus;
		} else {
			return dataStatus;
		}
	}

	function update (key, value = false, updateStatus = true) {
		if (updateStatus) setDataStatus('edited');

		if (
			typeof key === 'string' &&
			typeof value === 'object' &&
			typeof value.nativeEvent !== 'undefined'
		) {
			value = value.target.value;
		}

		if (/^\d+$/g.test(value)) value = Number(value);

		if (typeof value === 'function') {
			value = value(get(key));
		}

		const obj = typeof key === 'string' ? {[key]: value} : key;
		setInvalid(l => l.filter(i => !Object.keys(obj).includes(i)));

		Object.entries(obj).map(([key, value]) => {
			const oldValue = getFromDotNotation(data, key);

			setData(d => {
				const n = structuredClone(d);
				return setFromDotNotation(n, key, value);
			}, () => {
				_onUpdate(key, value, oldValue);
				if (updateStatus) _addHistory(key, value, oldValue);
			});
		});
	}

	function validate (extraValidation = false) {
		let invalidFields = Object.keys(required).filter(name => {
			const field = required[name];
			const value = getFromDotNotation(data, name);

			return (
				typeof field === 'undefined' || !field.checkValidity() ||
				typeof value === 'undefined' || value === ''
			);
		});

		if (typeof extraValidation === 'function') {
			const extraInvalidFields = extraValidation(data);

			if (typeof extraInvalidFields === 'object') {
				invalidFields = invalidFields.concat(Object.values(extraInvalidFields));
			}
		}

		setInvalid(invalidFields);
		return invalidFields.length === 0;
	}
};

export default useData;
