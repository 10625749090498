import {useEffect, useRef, useState} from 'react';

import {Autocomplete, TextField} from '@mui/material';

const SearchController = ({current, options, update}) => {
	const init = useRef(false);
	const timeout = useRef(false);

	const [term, setTerm] = useState(current);

	useEffect(() => {
		if (!init.current) return init.current = true;

		clearTimeout(timeout.current);

		timeout.current = setTimeout(() => update(term), 300);
	}, [term]);

	return (
		<Autocomplete
			freeSolo
			id="project-listing-search"
			options={Object.values(options)}
			size="small"
			value={term}
			onInputChange={(_e, newValue) => setTerm(newValue)}
			renderInput={params => <TextField {...params} sx={{width: 200}} label="Search"/>}
		/>
	);
};

export default SearchController;
