import hiddenTickets from '../../data/hidden-tickets';

export default function injectHidden(tickets) {
    const prep = summary => ({type: 'Sub-Task', summary, hidden: true});

    for (const epic of tickets) {
        switch (epic.summary) {
            case 'Setup':
                epic.subtickets[1].subtickets = hiddenTickets.pmSetup.map(prep);
                break;

            case 'Launch':
                epic.subtickets.map(ticket => {
                    if (ticket.summary === 'Pre-Launch Checklist') {
                        ticket.subtickets = hiddenTickets.preLaunch.map(prep);
                    }
                })
                break;
        }
    }

    return tickets;
}
