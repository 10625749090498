import {useContext, useEffect, useState} from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import useData from '../../hooks/useData';

import DataControl from '../../components/DataControl';

const INITIAL_DATA = {
	title: '',
	description: '',
}

const ScenarioDialog = ({mRef, isUpdate = false}) => {
	const [open, setOpen] = useState(false);
	const [callback, setCallback] = useState(false);

	const data = useContext(useData({title: '', description: ''}));

	useEffect(() => {
		mRef.current = {
			open: (current, cb) => {
				setOpen(true);
				setCallback(() => cb);

				if (!current) current = INITIAL_DATA;

				data.reset(current);
			}
		};
	}, []);

	/**
	 * Handles what happens when the user cancels the update.
	 */
	const handleCancel = () => setOpen(false);

	/**
	 * Handles what happens when the user confirms the update.
	 */
	const handleConfirm = () => {
		if (!data.validate()) return;

		callback(data.getData());
		setOpen(false);
	}

	return (
		<Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
			<DialogTitle>Create Scenario</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Typography mb={1}>Provide some basic information about the scenario.</Typography>

					<Stack spacing={3}>
						<DataControl
							component={TextField}
							label="Title"
							name="title"
							controller={data}
							required
						/>

						<DataControl
							component={TextField}
							multiline
							minRows={6}
							label="Description"
							name="description"
							controller={data}
						/>
					</Stack>
				</Stack>
			</DialogContent>

			<DialogActions sx={{padding: 3}}>
				<Button onClick={handleCancel}>Cancel</Button>
				<Button variant="contained" onClick={handleConfirm}>{isUpdate ? 'Update Scenario' : 'Create Scenario'}</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ScenarioDialog;
