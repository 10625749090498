import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, CircularProgress} from '@mui/material';

import useAuth from '../hooks/useAuth';

const UnAuthContent = ({children}) => {
	const navigate = useNavigate();
	const {loggedIn, loading} = useAuth();

	useEffect(() => {
		if (!loading && loggedIn) {
			navigate('/');
		}
	}, [loggedIn, loading, navigate]);

	return !loggedIn ? <>{children}</> : (
		<Box display="flex" justifyContent="center" padding={10}>
			<CircularProgress/>
		</Box>
	);
};

export default UnAuthContent;
