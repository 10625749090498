import {useContext, useEffect, useState} from 'react';
import m from 'moment/moment';

import {
	Button, CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

import useAppSettings from '../../hooks/useAppSettings';
import useData from '../../hooks/useData';

import DataControl from '../../components/DataControl';
import apiHelper from '../../providers/classes/API_Helper';
import useProject from '../../hooks/useProject';
import generateResults from '../../providers/generate-results';
import {resetCaches} from '@apollo/client';

const INITIAL_DATA = {title: '', status: 'Planning'};
const INITIAL_SCENARIO = {
	type: 'marketing',
	general: {
		projectManager: '',
		accountExecutive: '',
	},
};

const CreateProjectDialog = ({mRef}) => {
	const settings = useAppSettings();

	const [open, setOpen] = useState(false);
	const [callback, setCallback] = useState(false);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [updateHandlerTick, setUpdateHandlerTick] = useState(0);

	const project = useProject(INITIAL_DATA, INITIAL_SCENARIO);

	useEffect(() => {
		mRef.current = {
			open: cb => {
				setOpen(true);
				setCallback(() => cb);
				setError(false);

				project.data.reset(INITIAL_DATA);
				resetProjectScenario('marketing');
			},
		};
	}, []);

	useEffect(() => {
		resetProjectScenario(project.scenario.get('data.type'));
	}, [project.scenario.get('data.type')]);

	/**
	 * Resets the project scenario with the specified type configuration.
	 *
	 * @param type
	 */
	const resetProjectScenario = type => {
		const typeOptions = structuredClone(settings.defaultProject[type]);

		project.scenario.update('data.general', prev => ({
			...prev,
			...typeOptions.general,
		}));

		project.scenario.update('data.scope', typeOptions.scope);
	}

	/**
	 * Handles what happens when the user cancels the project creation.
	 */
	const handleCancel = () => setOpen(false);

	/**
	 * Handles what happens when the user confirms the project creation.
	 */
	const handleConfirm = async () => {
		if ([
			project.data.validate(),
			project.scenario.validate(),
		].includes(false)) return;

		setLoading(true);

		const results = generateResults(settings, project.scenario);
		const scenario = project.scenario.getData();

		scenario.data.budget = results.budget;
		scenario.data.launch = results.launch.format('YYYYMMDD');

		// Create the project.
		const projectId = await apiHelper.createProject(project.data.getData(), scenario);
		setLoading(false);

		if (!projectId) {
			setError(true);
			setTimeout(() => setOpen(false), 3000);
		} else {
			setOpen(false);
		}

		callback(projectId);
	};

	return (
		<Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
			<DialogTitle>Create Project</DialogTitle>
			<DialogContent>
				{loading ? (
					<CircularProgress/>
				) : error ? (
					<Typography>Could not create your project. Try again later.</Typography>
				) : (
					<Stack spacing={2}>
						<Typography mb={1}>Provide some basic information about the project.</Typography>

						<Stack spacing={3}>
							<DataControl
								component={TextField}
								label="Project Title"
								name="title"
								controller={project.data}
								required
							/>

							<DataControl
								component={Select}
								label="Project Type"
								name="data.type"
								controller={project.scenario}
								permissionType="edit_details"
								required>
								<MenuItem value="marketing">Marketing</MenuItem>
								<MenuItem value="magento">Magento</MenuItem>
								<MenuItem value="woocommerce">WooCommerce</MenuItem>
							</DataControl>

							<DataControl
								component={Select}
								label="PM"
								name="data.general.projectManager"
								controller={project.scenario}
								permissionType="edit_details"
								required>
								{settings.assignees.projectManagers.map((user, userKey) => (
									<MenuItem key={userKey} value={user.value}>{user.label}</MenuItem>
								))}
							</DataControl>

							<DataControl
								component={Select}
								label="AE"
								name="data.general.accountExecutive"
								controller={project.scenario}
								permissionType="edit_details"
								required>
								{settings.assignees.accountExecutives.map((user, userKey) => (
									<MenuItem key={userKey} value={user.value}>{user.label}</MenuItem>
								))}
							</DataControl>
						</Stack>
					</Stack>
				)}
			</DialogContent>

			{!loading && !error && (
				<DialogActions sx={{padding: 3}}>
					<Button onClick={handleCancel}>Cancel</Button>
					<Button variant="contained" onClick={handleConfirm}>Create Project</Button>
				</DialogActions>
			)}
		</Dialog>
	);
};

export default CreateProjectDialog;
