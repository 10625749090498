import {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from '@mui/material';

import TableItem from './ScenarioManager/TableItem';
import ConfirmDialog from '../../components/ConfirmDialog';
import ScenarioDialog from './ScenarioDialog';
import apiUpdateScenarioInfo from '../../providers/api/update-scenario-info';

const ScenarioManager = ({mRef, project, scenarios, create}) => {
	const confirmDialog = useRef();
	const scenarioDialog = useRef();

	const [open, setOpen] = useState(false);

	useEffect(() => {
		mRef.current = {open: () => setOpen(true)};
	}, []);

	const handleClose = () => setOpen(false);

	/**
	 * Edit a scenario.
	 */
	const handleEditItem = index => () => {
		scenarioDialog.current.open({
			title: scenarios[index].title,
			description: scenarios[index].description ?? '',
		}, data => project.updateScenarioInfo(scenarios[index].id, data));
	};

	/**
	 * Make a scenario the default one.
	 */
	const handleMakeDefault = index => () => {
		confirmDialog.current.open({
			title: 'Make Default',
			text: `Are you sure you want to make the "${scenarios[index].title}" the default one?`,
			cancelText: 'No, cancel',
			confirmText: 'Make default',
			onConfirm: () => project.changeDefaultScenario(scenarios[index].id),
		});
	};

	/**
	 * Remove a scenario from the project.
	 */
	const handleRemoveItem = index => () => {
		confirmDialog.current.open({
			title: 'Delete Scenario',
			text: `Are you sure you want to delete the "${scenarios[index].title}" scenario from this project? This action cannot be reversed.`,
			cancelText: 'No, keep it',
			confirmText: 'Yes, delete it',
			onConfirm: () => project.deleteScenario(scenarios[index].id),
		});
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between">
						Manage Scenarios

						<Button variant="outlined" size="small" onClick={create}>Create</Button>
					</Stack>
				</DialogTitle>

				<DialogContent>
					<Stack>
						{scenarios.map((item, key) => (
							<TableItem
								key={key}
								data={item}
								edit={handleEditItem(key)}
								remove={handleRemoveItem(key)}
								makeDefault={handleMakeDefault(key)}
							/>
						))}
					</Stack>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>

			<ConfirmDialog mRef={confirmDialog}/>
			<ScenarioDialog mRef={scenarioDialog} scenarios={scenarios} isUpdate/>
		</>
	);
};

export default ScenarioManager;
