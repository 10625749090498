export default function sortByDate (type, list) {
	const earlier = type === 'startDate' ? 1 : -1;
	const later = type === 'startDate' ? -1 : 1;

	return [...list]
		.sort((tc1, tc2) => {
			if (tc1[type].valueOf() > tc2[type].valueOf()) return earlier;
			if (tc1[type].valueOf() < tc2[type].valueOf()) return later;
			return 0;
		});
}
