import {useRef} from 'react';

import {Link, TableCell, TableRow} from '@mui/material';
import {Archive, Delete, Edit, StarHalf, Unarchive} from '@mui/icons-material';

import useAppSettings from '../../hooks/useAppSettings';

import DeleteDialog from './DeleteDialog';
import StatusChip from './StatusChip';
import StatusDialog from './StatusDialog';

import MenuButton from '../../components/MenuButton';
import ConfirmDialog from '../../components/ConfirmDialog';

import apiUpdateProjectArchivedStatus from '../../providers/api/update-project-archived-status';
import getDate from '../../providers/get-date';

export default function ProjectListingItem ({project, edit, updateStatus}) {
	const settings = useAppSettings();

	const confirmDialog = useRef();
	const deleteDialog = useRef();
	const statusDialog = useRef();

	const handleUpdateStatus = () => {
		statusDialog.current.open();
	};

	const handleUpdateArchivedStatus = () => {
		const options = {
			title: project.archived ?
				'Reactivate Project' :
				'Archive Project',
			text: project.archived ?
				'Are you sure you want to reactivate this project?' :
				'Are you sure you want to archive this project?',
			cancelText: 'Cancel',
			confirmText: project.archived ?
				'Reactivate Project' :
				'Archive Project',
			onConfirm: () => {
				apiUpdateProjectArchivedStatus(project.id, !project.archived)
					.then(() => window.location.reload());
			},
		};

		confirmDialog.current.open(options);
	};

	const handleDelete = () => {
		deleteDialog.current.open();
	};

	const contextMenuItems = [
		{label: 'Edit', Icon: Edit, action: edit},
		{label: 'Update Status', Icon: StarHalf, action: handleUpdateStatus},
		'-',
		{
			label: project.archived ? 'Reactivate' : 'Archive',
			Icon: project.archived ? Unarchive : Archive,
			action: handleUpdateArchivedStatus,
		},
	];

	if (project.archived) {
		contextMenuItems.push({
			label: 'Delete Permanently',
			Icon: Delete,
			action: handleDelete,
		});
	}

	return (
		<>
			<TableRow sx={{'&:last-child > *': {borderBottom: 'unset'}}}>
				<TableCell>
					<Link underline="hover" sx={{cursor: 'pointer'}} onClick={edit}>{project.title}</Link>
				</TableCell>
				<TableCell>
					<StatusChip status={project.archived ? 'Archived' : project.status}/>
				</TableCell>
				<TableCell>{settings.getAssignee(project.scenario.general.projectManager).label}</TableCell>
				<TableCell>{settings.getAssignee(project.scenario.general.accountExecutive).label}</TableCell>
				<TableCell>{project.scenario.budget}</TableCell>
				<TableCell>{getDate(project.scenario.kickoff).format('MMM DD, YYYY')}</TableCell>
				<TableCell>{getDate(project.scenario.launch).format('MMM DD, YYYY')}</TableCell>
				<TableCell>
					<MenuButton items={contextMenuItems}/>
				</TableCell>
			</TableRow>

			<ConfirmDialog mRef={confirmDialog}/>

			<DeleteDialog
				mRef={deleteDialog}
				project={project}
			/>

			<StatusDialog
				mRef={statusDialog}
				project={project}
				onUpdate={updateStatus}
			/>
		</>
	);
}
