const convertFromDotNotation = (obj) => {
    const index = (parent, key, value) => {
        const [mainKey, ...children] = key.split('.');
        parent[mainKey] = parent[mainKey] || {};

        if (children.length === 1) {
            parent[mainKey][children[0]] = value;
        } else {
            index(parent[mainKey], children.join('.'), value);
        }
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (key.includes('.')) {
            index(acc, key, value);
        } else {
            acc[key] = value;
        }

        return acc;
    }, {});
}

const getFromDotNotation = (o, path) => path.split('.').reduce((r, k) => r?.[k], o);

const setFromDotNotation = (o, path, value) => {
    Object.entries(path = path.split('.')).reduce((r, [k, p]) => {
        if (Number(k) === path.length - 1) {
            r[p] = value;
        } else if (typeof r[p] === 'undefined') {
            r[p] = {};
        }

        return r[p];
    }, o);

    return o;
}

export {
    convertFromDotNotation,
    getFromDotNotation,
    setFromDotNotation,
};
