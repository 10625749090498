import apiRequest from '../api-request';

export default async function apiUpdateScenarioInfo (project, scenario, info) {
	try {
		const response = await apiRequest('update_scenario_info', {project, scenario, info});

		return response.data.status === 'SUCCESS' ? response.data.scenariosList : null;
	} catch (e) {
		console.error('There was an error updating the scenario.', e);

		return false;
	}
}
