import axios from 'axios';

const isDev = process.env.NODE_ENV === 'development';
const useRemote = process.env.REACT_APP_USE_REMOTE === "true";
const apiURI = process.env[useRemote ? 'REACT_APP_REMOTE_API_URI' : 'REACT_APP_API_URI'];

const buildApiHelper = () => {
	return Object.freeze({
		createProject,
	});

	/**
	 * Makes a request to the API.
	 *
	 * @param {string} actionName
	 * @param {object} payload
	 * @returns {Promise<any>}
	 */
	async function makeRequest (actionName, payload) {
		if (isDev) console.log('--- Running action:', actionName);

		try {
			const response = await axios({
				url: apiURI + '/wp-admin/admin-ajax.php',
				method: 'POST',
				params: {action: 'admin'},
				data: {
					a: actionName,
					data: payload,
				},
				withCredentials: true,
			});

			if (isDev) console.warn(`Action: ${actionName} |`, response.data);

			return response.data;
		} catch (error) {
			if (isDev) console.error(`Action: ${actionName} | Error:`, error);

			return {status: 'ERROR'};
		}
	}

	/**
	 * Creates a new project.
	 *
	 * @param {object} info
	 * @param {object} scenario
	 * @returns {Promise<bool|int>}
	 */
	async function createProject (info, scenario) {
		const r = await makeRequest('create_project', {info, scenario});

		return r.status === 'SUCCESS' ? r.result.id : false;
	}
};

const apiHelper = buildApiHelper();

export default apiHelper;
