import {useMutation, gql} from '@apollo/client';
import {Alert, Box, Paper, Stack, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {GET_USER} from '../hooks/useAuth';
import UnAuthContent from '../components/UnAuthContent';

const LOG_IN = gql`
    mutation logIn($login: String!, $password: String!) {
        loginWithCookies(input: {
            login: $login
            password: $password
            rememberMe: true
        }) {
            status
        }
    }
`;

const Login = () => {
    const [logIn, {loading, error}] = useMutation(LOG_IN, {
        refetchQueries: [{query: GET_USER}],
    });

    const login = e => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const {login, password} = Object.fromEntries(data);

        logIn({variables: {login, password}})
            .catch(e =>  console.error(e));
    };

    return (
        <UnAuthContent>
            <Box sx={{maxWidth: 350, my: 10, mx: 'auto'}}>
                <Stack component={Paper} padding={4} spacing={2}>
                    <Typography>Log in with your Blacksmith account:</Typography>
                    <form onSubmit={login}>
                        <Stack spacing={2}>
                            <TextField
                                label={'Login'}
                                name={'login'}
                                fullWidth
                            />
                            <TextField
                                type={'password'}
                                label={'Password'}
                                name={'password'}
                                fullWidth
                            />
                            <LoadingButton
                                type={'submit'}
                                color={'primary'}
                                variant={'contained'}
                                loading={loading}>
                                Continue
                            </LoadingButton>
                        </Stack>
                    </form>

                    {error && <Alert severity={'error'}>Invalid credentials.</Alert>}
                </Stack>
            </Box>
        </UnAuthContent>
    );
};

export default Login;
