import {useEffect, useRef, useState} from 'react';

import {FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Switch, Tooltip} from '@mui/material';
import {HelpRounded} from '@mui/icons-material';
import MUIRichTextEditor from 'mui-rte';

const DataControl = params => {
	const {
		component,
		label = false,
		name,
		dataType = 'default',
		controller,
		onChange,
		children,
		helperText,
		permissionType = 'free',
		size = 'medium',
		...props
	} = params;

	const init = useRef(false);
	const field = useRef(null);
	const timeout = useRef();

	const [data, setData] = useState(controller.get(name, dataType));
	const [editing, setEditing] = useState(false);

	// Prepare the disabled property
	const disabled = typeof props.disabled === 'undefined' ?
		!controller.isEditable(permissionType) :
		props.disabled || !controller.isEditable(permissionType);

	if (typeof props.disabled !== 'undefined') delete props.disabled;

	// Attach an optional helper text to the input props.
	const InputProps = {};

	if (helperText) InputProps.endAdornment = (
		<InputAdornment position="end">
			<Tooltip title={helperText}>
				<IconButton edge="end">
					<HelpRounded/>
				</IconButton>
			</Tooltip>
		</InputAdornment>
	);

	if (Object.keys(InputProps).length > 0) {
		props.InputProps = InputProps;
	}

	/**
	 * Save this field as required.
	 */
	useEffect(() => {
		if (props.required && !props.disabled) {
			controller.addRequired(name, field.current);
		} else {
			controller.removeRequired(name);
		}

		return () => controller.removeRequired(name);
	}, [props.required, props.disabled]);

	/**
	 * Update the top-level data after a certain amount of time.
	 * Triggers when local data is updated.
	 */
	useEffect(() => {
		if (!init.current) return init.current = true;
		clearTimeout(timeout.current);

		timeout.current = setTimeout(() => {
			controller.update(name, dataType === 'date' ? data.format('YYYYMMDD') : data);
			setEditing(() => false);
		}, 300);
	}, [data]);

	/**
	 * Update the local data.
	 * Value can be formatted dynamically by the parent component.
	 */
	const handleChange = e => {
		let value;

		if (typeof onChange !== 'undefined') {
			value = onChange(e);
		} else {
			if (typeof e === 'object') {
				value = component === 'switch' ?
					e.target.checked :
					e.target.value;
			} else {
				value = e;
			}
		}

		if (props.multiple && value.includes('')) {
			setData(() => []);
		} else {
			setData(() => value);
		}

		setEditing(() => true);
	};

	/**
	 * Store the inner component which can be wrapped in a FormControl
	 * component in a few cases.
	 */
	let Inner;

	switch (component) {
		case 'rich':
			Inner = (
				<MUIRichTextEditor
					inputRef={field}
					defaultValue={editing ? (data ?? '') : (controller.get(name, dataType) ?? '')}
					controls={[
						'bold',
						'italic',
						'underline',
						'undo',
						'redo',
						'link',
						'numberList',
						'bulletList',
						'clear',
						'save',
					]}
					onSave={handleChange}
					{...(label ? {label} : {})}
				/>
			);
			break;

		case 'switch':
			Inner = (
				<Switch
					inputRef={field}
					onChange={handleChange}
					checked={editing ? data : controller.get(name, dataType)}
					disabled={disabled}
					{...(label ? {label} : {})}
					{...props}
				/>
			);
			break;

		default:
			// noinspection UnnecessaryLocalVariableJS
			const Component = component;
			Inner = (
				<Component
					inputRef={field}
					onChange={handleChange}
					value={editing ? (data ?? '') : (controller.get(name, dataType) ?? '')}
					error={controller.isInvalid(name)}
					disabled={disabled}
					size={size}
					fullWidth
					{...(label ? {label} : {})}
					{...props}>
					{children && (props.multiple ? data.length > 0 : data) && !props.required && (
						<MenuItem value=""><em>Clear</em></MenuItem>
					)}

					{children && children}
				</Component>
			);
			break;
	}

	return children ? (
		<FormControl required={props.required} size={size} fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			{Inner}
		</FormControl>
	) : Inner;
};

export default DataControl;
