import {useState} from 'react';

import {Divider, Fade, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList} from '@mui/material';
import {MoreVert} from '@mui/icons-material';

export default function MenuButton({items, iconSx = {}}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVert sx={iconSx} />
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{sx: {width: 220, maxWidth: '100%'}}}
                TransitionComponent={Fade}>
                <MenuList disablePadding>
                    {items.map((item, key) => {
                        const Icon = typeof item === 'string' ? false : item.Icon;
                        return item === '-' ?
                            <Divider key={key} /> :
                            <MenuItem key={key} onClick={item.action}>
                                {Icon && (
                                    <ListItemIcon>
                                        <Icon fontSize="small" />
                                    </ListItemIcon>
                                )}
                                <ListItemText>{item.label}</ListItemText>
                            </MenuItem>
                    })}
                </MenuList>
            </Menu>
        </>
    );
}
