import {createContext, useContext, useEffect, useState} from 'react';
import Cookie from 'js-cookie';

const preferredThemeCookie = Cookie.get('preferredTheme');

const DEFAULT_STATE = preferredThemeCookie ? preferredThemeCookie : (
	window.matchMedia &&
	window.matchMedia('(prefers-color-scheme: dark)').matches
) ? 'dark' : 'light';

const ThemePreference = createContext(DEFAULT_STATE);

const ThemePreferenceProvider = ({children}) => {
	const [theme, setTheme] = useState(DEFAULT_STATE);

	useEffect(() => Cookie.set('preferredTheme', theme), [theme]);

	const value = {
		preferredTheme: theme,
		setTheme,
	}

	return (
		<ThemePreference.Provider value={value}>{children}</ThemePreference.Provider>
	);
};

const useThemePreference = () => useContext(ThemePreference);

export {ThemePreferenceProvider};
export default useThemePreference;
