import m from 'moment';

import {TextField} from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {DesktopDatePicker, LocalizationProvider} from '@mui/lab';

import DataControl from './DataControl';

const DataControlDate = ({label, name, controller, onChange, ...props}) => {
    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <DataControl
                component={DesktopDatePicker}
                label={label}
                name={name}
                dataType="date"
                controller={controller}
                onChange={newDate => m(newDate)}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => <TextField {...params} fullWidth />}
                {...props}
            />
        </LocalizationProvider>
    );
}

export default DataControlDate;
