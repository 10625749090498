import apiRequest from '../api-request';

export default async function apiGetProjectScenario(project, scenario) {
    try {
        const response = await apiRequest('get_project_scenario', {project, scenario});
        return response.data.status === 'SUCCESS' ? response.data.scenario : false;
    } catch (e) {
        console.error('There was an error retrieving the project scenario.', e);
        return false;
    }
}
