import {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

import {
	AppBar,
	Box,
	Button,
	Container,
	Drawer,
	IconButton,
	Stack,
	Toolbar,
	Typography,
	useTheme,
} from '@mui/material';
import {
	Close,
	DarkMode,
	Logout,
	Settings,
	SettingsBrightness,
	WbSunny,
} from '@mui/icons-material';

import useAuth from '../hooks/useAuth';
import useThemePreference from '../hooks/useThemePreference';

import LogoLight from '../assets/logo-light.png';
import LogoDark from '../assets/logo-dark.png';
import {ToggleButton, ToggleButtonGroup} from '@mui/lab';

const Header = () => {
	const theme = useTheme();

	const {user} = useAuth();
	const {preferredTheme, setTheme} = useThemePreference();

	const [settingsOpen, setSettingsOpen] = useState(false);

	const pages = [{name: 'Projects', path: '/'}];

	const handleThemeChange = e => setTheme(() => e.target.value);

	return (
		<>
			<AppBar position="sticky" color="background" sx={{marginBottom: 4}}>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<Link to="/" style={{display: 'flex', alignItems: 'center'}}>
							<img
								src={theme.palette.mode === 'dark' ? LogoDark : LogoLight}
								style={{maxWidth: 130, maxHeight: 20}}
								alt="Blacksmith Agency"
							/>
						</Link>

						<Box className="top-menu" sx={{flexGrow: 1, display: 'flex', marginLeft: 4}}>
							{pages.map((page, key) => (
								<Button
									key={key}
									sx={{my: 2, display: 'block'}}
									component={NavLink}
									to={page.path}
									className="top-menu-link"
									activestyle={{color: 'gray'}}>
									{page.name}
								</Button>
							))}
						</Box>

						<Stack direction="row" spacing={2} alignItems="center">
							<Box sx={{flexGrow: 0, display: 'flex', alignItems: 'center'}}>
								<Typography sx={{my: 2, mr: 1}} variant="body2">{user.name}</Typography>
								<IconButton component={Link} to="/logout" aria-label="logout" color="primary"
											title="Log out">
									<Logout/>
								</IconButton>
							</Box>

							<Box flexShrink={0}>
								<IconButton onClick={() => setSettingsOpen(true)}>
									<Settings/>
								</IconButton>
							</Box>
						</Stack>
					</Toolbar>
				</Container>
			</AppBar>

			<Drawer
				anchor="right"
				open={settingsOpen}
				onClose={() => setSettingsOpen(false)}>
				<Stack minWidth={400}>
					<Box sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						py: 1,
						px: 2,
						borderBottom: '1px solid',
						borderBottomColor: theme.palette.divider,
					}}>
						Settings
						<IconButton onClick={() => setSettingsOpen(false)}>
							<Close/>
						</IconButton>
					</Box>

					<Stack spacing={1} padding={2}>
						<Typography variant="caption" gutterBottom>Mode</Typography>
						<ToggleButtonGroup
							color="primary"
							value={preferredTheme}
							exclusive
							onChange={handleThemeChange}
							fullWidth>
							<ToggleButton value="light">
								<WbSunny sx={{mr: 1, pointerEvents: 'none'}}/> Light
							</ToggleButton>
							<ToggleButton value="system">
								<SettingsBrightness sx={{mr: 1, pointerEvents: 'none'}}/> System
							</ToggleButton>
							<ToggleButton value="dark">
								<DarkMode sx={{mr: 1, pointerEvents: 'none'}}/> Dark
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				</Stack>
			</Drawer>
		</>
	);
};

export default Header;
