import {Route, Routes, useNavigate} from 'react-router-dom';
import {Container} from '@mui/material';

import Header from './components/Header';

import ProjectListingPage from './pages/ProjectListing';
import ProjectDetailPage from './pages/ProjectDetail';
import useAppSettings from './hooks/useAppSettings';
import {useEffect} from 'react';

const Dashboard = () => {
	const settings = useAppSettings();
	const navigate = useNavigate();

	useEffect(() => {
		if (
			typeof settings.maintenance !== 'undefined' &&
			settings.maintenance.active
		) navigate('/maintenance');
	}, [settings]);

	return (
		<>
			<Header/>

			<Container maxWidth="xl">
				<Routes>
					<Route path="/" element={<ProjectListingPage/>}/>
					<Route path="/archive" element={<ProjectListingPage archive/>}/>
					<Route path="/project/:id" element={<ProjectDetailPage/>}/>
				</Routes>
			</Container>
		</>
	);
};

export default Dashboard;
