import {Chip} from '@mui/material';

export default function StatusChip({status}) {
    const statusColors = {
        Planning: 'default',
        Signing: 'default',
        Signed: 'success',
        InProgress: 'info',
        Completed: 'success',
        Cancelled: 'error',
    };

    return (
        <Chip
            color={statusColors[status.replace(/\s/g, '')]}
            variant={['Planning', 'Cancelled'].includes(status) ? 'outlined' : 'filled'}
            size="small"
            label={status}
        />
    );
}
