const hiddenTickets = {
    pmSetup: [
        'Contract Signed',
        'Review MSA/SOW',
        'Identify Project Risks within Contract',
        'Identify approval process needed for scope additions',
        'Identify revision rounds, UAT rounds, etc',
        'Identify total unique layouts',
        'Identify 3rd party integrations sold to client',
        'Set up client within invoiced.com & add all necessary billing contacts',
        'Invoice Sent',
        'Invoice Paid',
        'Project Setup',
        'Create Google folder for client',
        'Clone out Digital Strategy and add to folders',
        'Clone out Meeting Minutes and add to folders',
        'AE fills out their portion of the Digital strategy doc',
        'Internal Kickoff',
        'Kickoff Call Scheduled',
        'Kickoff Call Completed',
        'Create new folder & filter within gmail to filter client emails accordingly',
        'Create Internal & external facing slack channels',
        'Create project within Jira',
        'Create jira tickets and import into project',
        'Create project within Hubstaff and integrate with Jira project (permission may need to be required)',
        'Set reminders in Hubstaff for 33%, 66%, and 90% of project',
        'Set up scheduled reports for client if required or documented in MSA',
        'Add budget to the project within hubstaff',
        'Send out AD Email Template',
        'Send out remaining invoices via Invoiced.com for the project',
        'Update the Payments tab with SOW payment terms and dates',
        'Update your 1:1 sheet to have client added',
        'Add the client to the Project Overview sheet',
    ],
    preLaunch: [
        'Create robots.txt File for the New Website,',
        'Update 301 Redirects to Point from Legacy URLs to New Website URLs,',
        'Check 301 Redirects to Ensure there are no Redirect Chains',
        'Ensure Client Has Access to an Administrative User on the New Website Backend (With Correct Email Tied to Account)',
        'Have Blog Authors Make a Password Reset Request on New Website Using Their Email',
        'Update Blog Author Icons and Any other Necessary User Information',
        'Check Blog Content to Ensure that It is Up to Date with Legacy Website',
        'Check Content and Format for Errors',
        'Ensure That No \'Lorem Ipsum\' Content is Present',
        'Ensure That All Required Content Areas Are Modifiable through Custom Fields',
        'Check Main Navigation Against Approved Site Architecture',
        'Check for Correct Contact Information - Office Addresses, Phone Numbers, etc...',
        'Check that All Inbound and Outbound Links Lead to Proper Page (200 Status Code)',
        'Check Design of Each Page on New Website for Responsiveness and Cross-Browser Compatibility',
        'Ensure All Image Rights are Purchased and Saved (No Watermarks Should be Found on Any Image)',
        'Ensure that Favicon is Loading',
        'Check Contact Forms Throughout the Website Ensure Forms are Sending to Appropriate Email',
        'If the Site is Using SMTP, Setup Contact Forms to Process Through Client\'s Email',
        'Check for 404 Error Page Which Should Include Sitemap. Ensure Non-Existent Links Redirect to this Page',
        'Ensure Pretty Permalinks for All Pages (No Numbers Should be Shown in Slug)',
        'Configure Yoast Premium for the New Website',
        'Check Title Tags & Meta Descriptions for Pages, Posts, and Other Relevant Crawable Content',
        'Ensure \'No Index, Follow\' Setting for Content that Should not be Indexed',
        'Ensure that Schema.org Local Business Metadata is Set Up',
        'Make Sure All Social Media Icons are Linked to the Correct Pages',
        'Ensure Each Page Has Relevant H1 Tag Set for Page Topic',
        'Check Images Throughout Website for Alt Tag Modifications',
        'Ensure Sitemap is Being Generated Correctly by Yoast and Ensure That Each URL Delivers a 200 Status Code Using Screaming Frog',
        'Install Tracking Code for Necessary Software (Google Analytics, Adwords, Hubspot, etc...)',
        'User Acceptance Testing Clearance - Apply Updates as Needed',
        'Test Site Speed to Ensure Page Load Time is Less Than 2s (Using GTMetrix and Pingdom) Fix Issues as Presented',
        'Run a Search on All Coded Assets to Ensure Root Relative URLs are Used Throughout to Help with Website Migration',
        'Create a Local Backup of Website Assets',
        'Push Latest Changes of Website to Bitbucket',
        'Add \'Site Design by Blacksmith\' to footer',
        'When using Pantheon hosting, SMTP is required for email to send correctly',
    ],
    siteMigration: [
        'Create a Backup of Legacy Website',
        'Setup Hosting Environment for New Website (Using PHP 7.2+)',
        'Setup MySQL Database for New Website (Using MySQL 5.7+)',
        'Populate Hosting Environment with Website Assets',
        'Import MySQL Database for New Website into Database Setup in Hosting Environment',
        'Run a Search and Replace on Database to Replace Development Domain for Actual Domain',
        'Configure Hosting Environment for Speed Optimization Including Caching, CDN Support, Server Side Compression, etc...',
    ],
    launchDay: [
        'Configure DNS to Have A Records Point to New Server Environment',
        'Provision SSL certificate to Ensure New Website is Being Sent Over a Secure Protocol',
        'Ensure that all http:// urls are redirecting to https:// and all https://www. urls are redirecting to https:// urls (For site migrations www. might be preferred CHECK WITH CLIENTS PREVIOUS SITE)',
        'Establish Automatic Backups of Website Environment and Database',
        'Submit All Version of New Website to Google Webmaster Tools (https://, https://www., http://, http://www.)',
        'Set Preferred Version of Website in Webmaster Tools (WMT)',
        'Check Crawlability of Website by Submitting robots.txt to WMT (Wordpress Setting \'Search Engine Visibility\' Should be Unchecked)',
        'Submit Sitemap(s) to WMT (Yoast Generates Several, All of Which are Valid Submissions)',
        'Use \'Fetch as Google\' to Fetch and Render the New Website and Request Indexing of the Site',
        'Sync Google Analytics and WMT',
        'Ensure Tracking Code is Live and Triggering Correctly (Google Tag Manager, Google Analytics, Adwords, Hubspot, etc...)',
        'Test Site Speed on Live Environment to Ensure Page Load Time is Less Than 2s (Using GTMetrix and Pingdom)',
        'Ensure that under \'Settings->General\' the \'Administration Email Address\' is not a BSA email address and is a client email address',
        'Quality Assurance Check',
    ],
};

export default hiddenTickets;
