import {useRef} from 'react';

import {
	Box,
	Grid,
	Stack,
	Typography,
} from '@mui/material';

import TableItem from './ContentTypeArea/TableItem';
import Sidebar from './ContentTypeArea/Sidebar';
import EntryDialog from './ContentTypeArea/EntryDialog';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import ConfirmDialog from '../../components/ConfirmDialog';

const ContentTypeArea = ({
	mRef,
	title,
	entryLabel,
	entryType,
	switchType,
	excludeFields,
	templateOptions,
	dataName,
	dataController,
}) => {
	const entryDialog = useRef();
	const confirmDialog = useRef();

	const currentItems = dataController.get(dataName);

	/**
	 * Add and item to the list.
	 */
	const handleAddItem = data => {
		dataController.update(dataName, item => ([...item, data]));
	};

	/**
	 * Edit an item on the list.
	 */
	const handleEditItem = index => () => {
		const dialogData = index === 'home' ?
			(dataController.get('data.scope.home') ?? {}) :
			currentItems[index];

		const dialogEntryLabel = index === 'home' ? 'Home' : entryLabel;

		entryDialog.current.open({
			title: `Edit ${dialogEntryLabel}`,
			reset: true,
			isHome: index === 'home',
			primaryAction: newData => {
				if (index === 'home') {
					dataController.update('data.scope.home', newData);
				} else {
					dataController.update(dataName, cItems => {
						cItems[index] = newData;
						return [...cItems];
					});
				}
			},
		}, dialogData);
	};

	/**
	 * Remove an item from the list.
	 */
	const handleRemoveItem = index => () => {
		confirmDialog.current.open({
			title: 'Delete Item',
			text: [
				`Are you sure you want to delete [${currentItems[index].title}] from this project? This action cannot be reversed.`,
				"If you wish to test the effects of removing this item, you can toggle it's visibility on the item's actions.",
			],
			cancelText: 'No, keep it',
			confirmText: 'Yes, delete it',
			onConfirm: () => {
				dataController.update(dataName, item => {
					item.splice(index, 1);

					return [...item];
				});
			},
		});
	};

	/**
	 * Toggles an item's visibility.
	 */
	const handleToggleItem = index => () => {
		dataController.update(dataName, items => {
			items[index].enabled = !items[index].enabled;

			return [...items];
		});
	};

	/**
	 * Sort an item up or down the list.
	 */
	const handleSortItem = result => {
		if (!result.destination) return;
		const {index} = result.source;
		const {index: destination} = result.destination;

		dataController.update(dataName, items => {
			const [item] = items.splice(index, 1);
			items.splice(destination, 0, item);

			return [...items];
		});
	};

	return (
		<div>
			<EntryDialog mRef={entryDialog} type={entryType} excludeFields={excludeFields} switchType={switchType}/>
			<ConfirmDialog mRef={confirmDialog}/>

			<Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
				<Typography variant="h6">{title}</Typography>
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={8}>
					{currentItems.length === 0 && entryType !== 'page' ? (
						<Typography variant="subtitle2">Nothing added.</Typography>
					) : (
						<>
							{entryType === 'page' && (
								<TableItem
									data={dataController.get('data.scope.home') ?? {}}
									dataController={dataController}
									edit={handleEditItem('home')}
									isHome
								/>
							)}

							<DragDropContext onDragEnd={handleSortItem}>
								<Droppable droppableId="droppable">
									{provided => (
										<Stack
											ref={provided.innerRef}
											{...provided.droppableProps}>
											{currentItems.map((item, key) => (
												<Draggable key={key} draggableId={`item-${key}`} index={key}>
													{provided => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}>
															<TableItem
																data={item}
																dataController={dataController}
																edit={handleEditItem(key)}
																remove={handleRemoveItem(key)}
																toggle={handleToggleItem(key)}
																dragHandleProps={provided.dragHandleProps}
															/>
														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</Stack>
									)}
								</Droppable>
							</DragDropContext>
						</>
					)}
				</Grid>

				<Grid item xs={4}>
					<Sidebar
						mRef={mRef}
						entryLabel={entryLabel}
						entryType={entryType}
						switchType={switchType}
						excludeFields={excludeFields}
						templateOptions={templateOptions}
						addNew={handleAddItem}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContentTypeArea;
