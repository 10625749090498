import {useEffect, useRef} from 'react';

import {
    Box,
    Button,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';

import EntryDialog from './EntryDialog';

const Sidebar = ({mRef, entryLabel, entryType, switchType, excludeFields, templateOptions, addNew}) => {
    const entryDialog = useRef();

    /**
     * Creates a new, custom entry.
     */
    const handleCreate = (template = false) => () => {
        if (template) return addNew(template);

        entryDialog.current.open({
            title: `Create ${entryLabel}`,
            primaryAction: addNew,
            reset: true,
        }, {type: entryType});
    }

    useEffect(() => {
        if (mRef) mRef.current = handleCreate();
    }, []);

    return (
        <>
            <EntryDialog mRef={entryDialog} excludeFields={excludeFields} type={entryType} switchType={switchType} />

            <Box component={Paper} elevation={2}>
                <Box sx={{
                    p: 2,
                    pb: 0,
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    <Typography variant="subtitle2">Add from template</Typography>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                        <Typography variant="subtitle2" marginRight={1}>or</Typography>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={handleCreate()}>
                            Create
                        </Button>
                    </Box>
                </Box>

                <List sx={{maxHeight: 360, overflow: 'auto'}} disablePadding>
                    {templateOptions.map((option, optionKey) => {
                        return typeof option === 'string' ?
                            <ListSubheader key={optionKey}>{option}</ListSubheader> :
                            <ListItemButton onClick={handleCreate(option)} key={optionKey}>
                                <ListItemText>{option.title}</ListItemText>
                                <AddIcon color="primary"/>
                            </ListItemButton>
                    })}
                </List>
            </Box>
        </>
    );
}

export default Sidebar;
