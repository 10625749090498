import {useContext, useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {
	Alert,
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Pagination,
	PaginationItem,
	Paper,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';

import useAppSettings from '../hooks/useAppSettings';
import useAuth from '../hooks/useAuth';
import useData from '../hooks/useData';

import apiGetProjects from '../providers/api/get-projects';

import AuthContent from '../components/AuthContent';
import DataControl from '../components/DataControl';
import PageHeader from '../components/PageHeader';

import ProjectListingItem from './ProjectListing/ProjectListingItem';
import SearchController from './ProjectListing/SearchController';
import apiHelper from '../providers/classes/API_Helper';
import CreateProjectDialog from './ProjectListing/CreateProjectDialog';

const Navigation = ({max, page}) => max > 1 ? (
	<Pagination
		count={max}
		page={page}
		shape="rounded"
		size="small"
		renderItem={item => (
			<>
				<PaginationItem
					component={Link}
					to={item.page === 1 ? '' : `?page=${item.page}`}
					{...item}
				/>
			</>
		)}
	/>
) : null;

const ProjectListing = ({archive = false}) => {
	const {user} = useAuth();

	const init = useRef(false);
	const createProjectDialog = useRef();

	const navigate = useNavigate();
	const settings = useAppSettings();

	const search = window.location.search;
	const [query, setQuery] = useState(new URLSearchParams(search));
	const [page, setPage] = useState(parseInt(query.get('page') || 1, 10));

	const [searchTerm, setSearchTerm] = useState('');

	const filters = useContext(useData({
		accountExecutive: [],
		projectManager: [],
	}));

	const [loading, setLoading] = useState(true);
	const [maxPages, setMaxPages] = useState(0);
	const [list, setList] = useState([]);
	const [error, setError] = useState(false);

	// Load the projects list.
	useEffect(() => {
		setList(() => ([]));
		setLoading(true);

		apiGetProjects({
			user,
			archive,
			filters: filters.getData(),
			term: searchTerm,
			page,
		}).then(response => {
			setLoading(false);
			init.current = true;

			if (response.posts) {
				setList(() => response.posts);
				setMaxPages(() => response.maxPages);
			} else {
				setError(true);
			}
		});
	}, [archive, searchTerm, filters.getData(), page]);

	useEffect(() => setQuery(new URLSearchParams(search)), [search]);

	useEffect(() => {
		if (!init.current) return;
		const p = parseInt(query.get('page') || 1, 10);
		if (p !== page) setPage(p);
	}, [query]);

	useEffect(() => {
		if (!init.current) return;
		setPage(1);
		navigate('');
	}, [filters.getData()]);

	/**
	 * Handle editing a project.
	 */
	const handleEdit = id => () => {
		navigate('project/' + id);
	};

	/**
	 * Handles creating a new project.
	 */
	const handleCreateProject = () => {
		createProjectDialog.current.open(projectId => {
			if (projectId) navigate(`project/${projectId}`);
		});
	}

	/**
	 * Handle updating the status of a project.
	 */
	const handleUpdateStatus = id => status => {
		setList(cList => [
			...cList.map(p => {
				if (p.id === id) p.status = status;
				return p;
			}),
		]);
	};

	/**
	 * Handle what happens with the archived filter.
	 */
	const handleArchivedFilter = () => {
		navigate(archive ? '/' : '/archive');
	};

	return (
		<AuthContent>
			<PageHeader
				title="Projects List"
				CustomLeft={(
					<Button
						variant="contained"
						onClick={handleCreateProject}>
						Create Project
					</Button>
				)}
			/>

			<Stack spacing={2}>
				<Stack direction="row" alignItems="flex-end" justifyContent="space-between">
					<Stack spacing={2} direction="row">
						<ToggleButtonGroup
							color="primary"
							size="small"
							value={archive ? 'archived' : 'active'}
							onChange={handleArchivedFilter}
							sx={{whiteSpace: 'nowrap'}}
							exclusive>
							<ToggleButton value="active" sx={{px: 2}}>Active
								({settings.projectCount.active})</ToggleButton>
							<ToggleButton value="archived" sx={{px: 2}}>Archived
								({settings.projectCount.archived})</ToggleButton>
						</ToggleButtonGroup>

						<Stack spacing={2} direction="row">
							<SearchController
								current={searchTerm}
								options={settings.simpleProjectsList.active}
								update={n => setSearchTerm(n)}
							/>

							<DataControl
								component={Select}
								label="Project Manager"
								name="projectManager"
								controller={filters}
								size="small"
								multiple
								sx={{width: 200}}>
								{settings.assignees.projectManagers.map((pm, key) => (
									<MenuItem key={key} value={pm.value}>{pm.label}</MenuItem>
								))}
							</DataControl>

							<DataControl
								component={Select}
								label="Account Executive"
								name="accountExecutive"
								controller={filters}
								size="small"
								multiple
								sx={{width: 200}}>
								{settings.assignees.accountExecutives.map((ae, key) => (
									<MenuItem key={key} value={ae.value}>{ae.label}</MenuItem>
								))}
							</DataControl>
						</Stack>
					</Stack>

					<Box display="flex" justifyContent="flex-end">
						<Navigation max={maxPages} page={page}/>
					</Box>
				</Stack>

				{loading ? (
					<CircularProgress/>
				) : error ? (
					<Alert severity="error" variant="filled">There was an error loading projects.</Alert>
				) : list.length === 0 ? (
					<Alert severity="info">
						{archive ? 'There are no archived projects.' : 'There are no projects are available in your scope.'}
					</Alert>
				) : (
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Title</TableCell>
									<TableCell width={150}>Status</TableCell>
									<TableCell width={100}>PM</TableCell>
									<TableCell width={100}>AE</TableCell>
									<TableCell width={100}>Budget</TableCell>
									<TableCell width={100}>Kickoff</TableCell>
									<TableCell width={100}>Launch</TableCell>
									<TableCell width={34}></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{list.map((project, key) => (
									<ProjectListingItem
										key={key}
										edit={handleEdit(project.id)}
										updateStatus={handleUpdateStatus(project.id)}
										project={project}
									/>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}

				<Box display="flex" justifyContent="flex-end">
					<Navigation max={maxPages} page={page}/>
				</Box>

				<CreateProjectDialog mRef={createProjectDialog}/>
			</Stack>
		</AuthContent>
	);
};

export default ProjectListing;
