import injectAssignees from './results/inject-assignees';
import injectDates from './results/inject-dates';
import injectDependencies from './results/inject-dependencies';
import injectHidden from './results/inject-hidden';
import injectIDs from './results/inject-ids';

import calculateProjectSummary from './calculate-project-summary';
import createEpics from './results/create-results';
import calculateCost from './calculate-cost';

const generateResults = (settings, controller) => {
    const epics = createEpics(settings, controller);

    let tickets;

    tickets = injectDates(epics, controller.get('data.kickoff', 'date'), settings.holidays);
    tickets = injectHidden(tickets);
    tickets = injectIDs(tickets);
    tickets = injectDependencies(tickets);
    tickets = injectAssignees(settings, tickets, controller);

    // Add kickoff & launch dates
    const kickoff = controller.get('data.kickoff', 'date');
    const launch = tickets[tickets.length - 1].endDate;

    const durationDays = launch.diff(kickoff, 'day');
    const durationWeeks = launch.diff(kickoff, 'week');

    const bufferedDurationDays = Math.ceil((durationDays * 17) / 100);
    launch.add(bufferedDurationDays, 'days');

    const durationMonths = launch.diff(kickoff, 'months');

    // Calculate total hours & overall duration
    const prodHours = tickets.reduce((total, epic) => total + epic.estimateDisplay, 0);
    const pmHours = Number(controller.get('data.general.managementTime')) * durationWeeks;
    const totalHours = prodHours + pmHours;

    // Add content types count
    const content = {
        pages: controller.get('data.scope.modules').filter(c => c.type === 'page' && c.enabled).length,
        features: controller.get('data.scope.modules').filter(c => c.type === 'feature' && c.enabled).length,
        integrations: controller.get('data.scope.integrations').filter(c => c.enabled).length,
    };

    // Add budget
    const budget = calculateCost(totalHours, controller.get('data.general.hourlyRate'));

    // Add summary
    const projectSummary = calculateProjectSummary(tickets, pmHours, controller);

    return Object.freeze({
        tickets,
        kickoff,
        launch,
        prodHours,
        totalHours,
        duration: durationMonths,
        content,
        budget,
        projectSummary,
    });
};

export default generateResults;
