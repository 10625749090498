import {Box, Grid, MenuItem, Select, Typography} from '@mui/material';

import DataControl from '../../components/DataControl';

const ProductionTeamArea = ({dataController, options}) => {
    const categories = [
        {name: 'seniorDesigner', options: options.seniorDesigners, label: 'Senior Designer'},
        {name: 'seniorDeveloper', options: options.seniorDevelopers, label: 'Senior Developer'},
        {name: 'uxStrategist', options: options.uxStrategists, label: 'UX Strategist'},
        {name: 'juniorDesigner', options: options.juniorDesigners, label: 'Junior Designer'},
        {name: 'juniorDeveloper', options: options.juniorDevelopers, label: 'Junior Developer'},
    ];

    return (
        <div>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4}}>
                <Typography variant="h6">Production Team</Typography>
            </Box>

            <Grid container spacing={3}>
                {categories.map((cat, catKey) => (
                    <Grid key={catKey} item xs={4}>
                        <DataControl
                            component={Select}
                            label={cat.label}
                            name={`data.general.productionTeam.${cat.name}`}
                            controller={dataController}
                            permissionType="edit_details"
                            required>
                            {cat.options.map((user, userKey) => (
                                <MenuItem key={userKey} value={user.value}>{user.label}</MenuItem>
                            ))}
                        </DataControl>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default ProductionTeamArea;
