import apiRequest from '../api-request';

export default async function apiUpdateProjectStatus(id, status) {
    try {
        await apiRequest('update_project_status', {id, status});
        return true;
    } catch (e) {
        console.error('There was an error updating the project status.', e);
        return false;
    }
}