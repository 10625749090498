import {useRef, useState} from 'react';

import {
	Box,
	Chip,
	Divider,
	IconButton,
	Link,
	Paper,
	Popover,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';

import {
	Delete,
	DescriptionRounded,
	Edit,
	Error,
	Menu,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';

import calculateCost from '../../../providers/calculate-cost';
import MenuButton from '../../../components/MenuButton';

const TableItem = ({data, dataController, edit, remove, toggle, isHome, dragHandleProps = false}) => {
	const notesButton = useRef();

	const [notesOpen, setNotesOpen] = useState(false);

	const iconButtonSize = {width: 18, height: 18};
	if (typeof data.enabled === 'undefined') data.enabled = true;

	// Calculate total hours & cost for display
	const totalHours = Object.entries(data).reduce((t, [key, value]) => {
		return ([
			'wiresHours',
			'designHoursDesktop',
			'designHoursMobile',
			'developmentHours',
		].includes(key)) ? t + Number(value) : t;
	}, 0);

	const totalCost = calculateCost(totalHours, dataController.get('data.general.hourlyRate'));

	// Get the notes.
	const notesLabels = {
		wires: 'Wires',
		designDesktop: 'Design (Desktop)',
		designMobile: 'Design (Mobile)',
		development: 'Development',
	};

	const notes = Object.entries(data.notes || {})
		.reduce((t, [k, n]) => Boolean(n) ? [...t, {label: notesLabels[k], text: n}] : t, []);

	// Prepare the context items.
	const contextMenuItems = [
		{label: 'Edit', Icon: Edit, action: edit},
		...(!isHome ? [{label: 'Delete', Icon: Delete, action: remove}] : []),
	];

	// Check if this item has any estimates.
	const hasEstimates =
		(Boolean(data.wiresDays) && Boolean(data.wiresHours)) ||
		(Boolean(data.designDaysDesktop) && Boolean(data.designHoursDesktop)) ||
		(Boolean(data.designDaysMobile) && Boolean(data.designHoursMobile)) ||
		(Boolean(data.developmentDays) && Boolean(data.developmentHours));

	return (
		<Box
			component={Paper}
			elevation={2}
			gap={2}
			sx={{
				pt: 1.1,
				pb: 0.9,
				px: 2,
				mb: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				opacity: data.enabled ? 1 : 0.5,
			}}>
			<Stack direction="row" spacing={2} alignItems="center">
				{dragHandleProps && (
					<Box {...dragHandleProps} sx={{height: 20}}>
						<Menu sx={{width: 18, height: 18}}/>
					</Box>
				)}

				<Typography variant="body2">
					<Link onClick={edit} underline="none" sx={{cursor: 'pointer'}}>{data.title}</Link>
				</Typography>

				{data.type === 'feature' && (
					<Chip size="small" variant="outlined" label="Feature"/>
				)}
			</Stack>

			<Stack direction="row" alignItems="center" spacing={2}>
				{hasEstimates ? (
					<Stack direction="row" spacing={1}>
						<Chip size="small" variant="outlined" label={`${totalHours} hours`}/>
						<Chip size="small" label={totalCost}/>
					</Stack>
				) : (
					<Tooltip title={
						<Typography variant="caption">
							This item has no estimates and is not being considered when calculating costs, budget and dates for this project.
						</Typography>
					}>
						<Chip
							size="small"
							color="warning"
							label="No Data"
							variant="outlined"
							icon={<Error/>}
						/>
					</Tooltip>
				)}

				<Stack direction="row">
					<Popover
						open={notesOpen}
						onClose={() => setNotesOpen(false)}
						anchorEl={notesButton.current}
						anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
						transformOrigin={{vertical: 'top', horizontal: 'right'}}
						elevation={5}>
						<Box sx={{
							minWidth: 300,
							maxWidth: 350,
							maxHeight: 400,
						}}>
							<Stack spacing={1} sx={{px: 2, pt: 1.5, pb: 2}}>
								{notes.map((n, k) => (
									<Box key={k}>
										<Box sx={{pt: 0.8, pb: 1.5, '&:last-child': {pb: 0}}}>
											<Stack>
												<Typography variant="caption" marginBottom={1}>
													<strong>{n.label}</strong>
												</Typography>

												{n.text.split('\n').map((l, lk) => (
													<Typography key={lk} variant="caption">{l}</Typography>
												))}
											</Stack>
										</Box>

										{k < (notes.length - 1) && <Divider/>}
									</Box>
								))}
							</Stack>
						</Box>
					</Popover>

					<IconButton
						ref={notesButton}
						title="Notes"
						disabled={notes.length === 0}
						onClick={() => setNotesOpen(true)}>
						<DescriptionRounded sx={iconButtonSize}/>
					</IconButton>

					<IconButton
						title="Change Visibility"
						disabled={isHome}
						onClick={toggle}>
						{data.enabled ? (
							<Visibility sx={iconButtonSize}/>
						) : (
							<VisibilityOff sx={iconButtonSize}/>
						)}
					</IconButton>

					<MenuButton
						items={contextMenuItems}
						iconSx={iconButtonSize}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default TableItem;
