export default function isDesignTicket(parent, ticket) {
    if (!parent || parent.type !== 'Epic') return false;

    const designEpicNames = [
        'Wires',
        'Inner Design - Desktop',
        'Inner Design - Mobile',
    ];

    return designEpicNames.includes(parent.summary) && ticket.type === 'Design';
}
