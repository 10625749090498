import {useRef} from 'react';

import {Grid, Stack} from '@mui/material';

import useAppSettings from '../../../hooks/useAppSettings';

import ContentTypeArea from '../ContentTypeArea';
import ContentPopArea from '../ContentPopArea';
import AdditionalOptionsArea from '../AdditionalOptionsArea';

const Content = ({project}) => {
    const settings = useAppSettings();
    const createType = useRef();

    const prepareModules = () => {
        const projectType = project.scenario.get('data.type');
        const pages = settings.contentOptions.pages[projectType];

        return [
            'Pages',
            ...pages,
            'Features',
            ...settings.contentOptions.features,
        ];
    }

    const switchType = () => createType.current();

    return (
        <Stack spacing={4}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <ContentTypeArea
                        mRef={createType}
                        title="Pages & Features"
                        entryLabel="Module"
                        entryType="page"
                        templateOptions={prepareModules()}
                        dataName="data.scope.modules"
                        currentList={project.scenario.get('data.scope.modules')}
                        dataController={project.scenario}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ContentTypeArea
                        title="Integrations"
                        entryLabel="Integration"
                        entryType="integration"
                        switchType={switchType}
                        excludeFields={['wires', 'design', 'type']}
                        templateOptions={settings.contentOptions.integrations}
                        dataName="data.scope.integrations"
                        currentList={project.scenario.get('data.scope.integrations')}
                        dataController={project.scenario}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ContentPopArea
                        type="population"
                        title="Content Population"
                        label="Number of pages"
                        name="data.scope.contentPop"
                        helperText={`The total number of individual pages that will be created manually.`}
                        currentValue={project.scenario.get('data.scope.contentPop.value')}
                        dataController={project.scenario}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ContentPopArea
                        type="migration"
                        title="Content Migration"
                        label="Number of batches"
                        name="data.scope.contentMig"
                        helperText={`A migration batch can be considered as a group of content that will be migrated automatically. Example: blog posts, videos, events.`}
                        currentValue={project.scenario.get('data.scope.contentMig.value')}
                        dataController={project.scenario}
                    />
                </Grid>
            </Grid>

            <AdditionalOptionsArea controller={project.scenario} />
        </Stack>
    );
};

export default Content;
