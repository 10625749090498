import {createContext, useContext, useEffect, useState} from 'react';
import m from 'moment';

import {Box, CircularProgress} from '@mui/material';

import apiRequest from '../providers/api-request';

const DEFAULT_STATE = {
	managementWeight: 0,
	hourlyRate: 0,
	assignees: {
		accountExecutives: [],
		projectManagers: [],
		uxStrategists: [],
		juniorDesigners: [],
		seniorDesigners: [],
		juniorDevelopers: [],
		seniorDevelopers: [],
		other: [],
	},
	holidays: [],
};

const AppSettings = createContext(DEFAULT_STATE);

const AppSettingsProvider = ({children}) => {
	const [ready, setReady] = useState(false);
	const [settings, setSettings] = useState(DEFAULT_STATE);

	useEffect(() => {
		apiRequest('get_config')
			.then(({data}) => {
				setSettings(cSettings => {
					cSettings.defaultProject = Object.keys(data.defaultProject).reduce((list, type) => ({
						...list, [type]: {type, ...data.defaultProject[type]}
					}), {});

					cSettings.holidays = data.holidays.map(date => m(date, 'YYYY-MM-DD'));
					cSettings.contentOptions = data.contentOptions;

					// Add available assignees
					if (data.assignees) {
						for (const key in data.assignees) {
							cSettings.assignees[key] = data.assignees[key];
						}
					}

					// Add maintenance data
					cSettings.maintenance = {
						active: data.maintenance.active,
						end: data.maintenance.active ? m(data.maintenance.end, 'YYYY-MM-DD hh:mm:ss') : false,
					};

					// Add generic project data
					cSettings.projectCount = data.projectCount;

					// A simple list of project titles
					cSettings.simpleProjectsList = data.simpleProjectsList;

					return {...cSettings};
				});

				setReady(true);
			})
			.catch(error => {
				console.error('There was an error fetching the app settings.');
				console.error(error);
			});
	}, []);

	/**
	 * Get a specific assignee from the list.
	 *
	 * @param key
	 * @returns {string}
	 */
	settings.getAssignee = key => {
		if (!key) return '';

		const userFound = Object.values(settings.assignees).reduce((user, list) => {
			const [u] = list.filter(({value}) => value === key);
			if (u) user = u;
			return user;
		}, null);

		return userFound ?? '';
	};

	return (
		<AppSettings.Provider value={settings}>
			{ready ? children : (
				<Box display="flex" justifyContent="center" padding={10}>
					<CircularProgress/>
				</Box>
			)}
		</AppSettings.Provider>
	);
};

const useAppSettings = () => useContext(AppSettings);

export {AppSettingsProvider};
export default useAppSettings;
