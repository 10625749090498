import isDesignTicket from '../is-design-ticket';

export default function injectDependencies(epics) {
    const result = [];

    // Adds the dependent to the list of blocked tickets
    // in the specified ticket.
    function isBlockedByTicket(epicName, ticketName, dependent) {
        for (const epic of result) {
            if (epic.summary === epicName) {
                for (const ticket of epic.subtickets) {
                    if (ticket.internalName === ticketName) {
                        ticket.blocks.push(dependent);
                    }
                }
            }
        }
    }

    // Injects dependencies between subtickets
    function injectSub({tickets, parent}) {
        const parentBlocks = [...parent.blocks];

        const subResult = [];

        for (const ticketIndex in tickets) {
            const ticket = {
                ...tickets[ticketIndex],
                blocks: [],
            };

            // ? Non-first ticket
            // = Previous tickets block it
            if (!isDesignTicket(parent, ticket)) {
                const previousTickets = subResult.filter(tc => tc.order === ticket.order - 1).map(tc => tc.ID);

                subResult.map(tc => {
                    if (previousTickets.includes(tc.ID)) {
                        tc.blocks.push(ticket.ID);
                    }

                    return tc;
                });
            }

            // ? Wires Approval
            // = All wires tickets block it
            if (ticket.type === 'Client Task' && ticket.summary === 'Approval: Wires') {
                subResult.map(tc => {
                    if (isDesignTicket(parent, tc) && !tc.blocks.includes(ticket.ID)) {
                        tc.blocks.push(ticket.ID);
                    }

                    return tc;
                });
            }

            // ? Page Development
            // = Design counterpart blocks it
            if (ticket.internalType === 'page-dev') {
                isBlockedByTicket('Inner Design - Desktop', ticket.internalName, ticket.ID);
            }

            // ? Feature Development
            // = Design counterpart blocks it
            if (ticket.internalType === 'feature-dev') {
                isBlockedByTicket('Inner Design - Desktop', ticket.internalName, ticket.ID);
            }

            // * Prepare subtickets
            if (ticket.subtickets.length > 0) {
                const deeperResult = injectSub({tickets: ticket.subtickets, parent: ticket});

                ticket.blocks = deeperResult.parentBlocks;
                ticket.subtickets = deeperResult.result;
            }

            subResult.push(ticket);
        }

        return {parentBlocks, result: subResult};
    }

    // Inject each epic
    for (const epicIndex in epics) {
        const epic = {
            ...epics[epicIndex],
            blocks: [],
        };

        // ? Non-first epics
        if (epicIndex > 0) {
            result[epicIndex - 1].blocks.push(epic.ID);
        }

        // * Prepare subtickets
        if (epic.subtickets.length > 0) {
            const subResult = injectSub({tickets: epic.subtickets, parent: epic});

            epic.blocks = subResult.parentBlocks;
            epic.subtickets = subResult.result;
        }

        result.push(epic);
    }

    return result;
}
